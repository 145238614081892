import React from "react";
import { Col, Row, Flex, Button } from "antd";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Image, Pagination } from "antd";
import SearchComponent from "../dictionary-alphabet/seach-component";
import "./uniqueLetter.css";

import Breadcramb from "../breadcramb";

import Card from "antd/es/card/Card";

const UniqueLetterComponent = ({ letter }) => {
  const { pathname } = useLocation();

  const CustomCountriesCard = ({ content }) => (
    <Card
      className="countries-custom-card"
      content={content}
    >
      <p style={{ color: "black" }}>{content}</p>
    </Card>
  );

  const urlLetter = `https://morning-plains-82582-f0e7c891044c.herokuapp.com/alphabet/${letter}`;

  const [letterData, setLetterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //fetch category datas
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(urlLetter, {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       });

  //       if (response.ok) {
  //         const responseData = await response.json();
  //         setLetterData(responseData);
  //         console.log(letterData);
  //       } else {
  //         console.error("API error:", response.statusText);
  //       }
  //     } catch (error) {
  //       console.error("Fetch error:", error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [urlLetter]);

  //all words tab
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);
  const [per, setPer] = useState();


  const fetchData = async (page) => {
    try {
      const response = await fetch(`${urlLetter}?page=${page}&per_page=20`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setLetterData(responseData);
        const totalPages = Math.ceil(responseData.pagination.total_items / 20);
        console.log(totalPages);
        setTotalNumberOfPages(totalPages);
        setCurrentPage(responseData.pagination.page);
        // setPer(responseData.pagination.page);
      } else {
        console.error("API error:", response.statusText);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1); //  initial data
  }, []); // empty  array ensures the effect runs only once

  const handlePageChange = (page) => {
    console.log("New Page:", page);
    console.log(totalNumberOfPages);
    fetchData(page);
  };

  return (
    <>
     <div className="container-for-all">
     <Flex gap="small" vertical>
        {pathname.includes("/category") ? (
          <Breadcramb
            topCategoryName="All Categories"
            categoryId="2"
            categoryName="b"
          />
        ) : pathname.includes("/sentence") ? (
          <Breadcramb
            topCategoryName="Sentences"
            categoryId="2"
            categoryName="b"
          />
        ) : pathname.includes("/alphabet") ? (
          <Breadcramb
          
            topCategoryName="Əlifba"
            // categoryId="2"
            categoryName={letter}
          />
        ) : (
          // Render content when the path does not include "/category", "/sentence", or "/alphabet"
          <div>
            <h1>Not a Category, Sentence, or Alphabet Page</h1>
            {/* Other content specific to when the path does not include "/category", "/sentence", or "/alphabet" */}
          </div>
        )}

        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100vh",
            }}
          >
            <div className="three-body">
              <div className="three-body__dot"></div>
              <div className="three-body__dot"></div>
              <div className="three-body__dot"></div>
            </div>
          </div>
        ) : (
          <div className="body-margin">
            
           < SearchComponent/>

            <Row gutter={[16, 16]}>
              <Col
                style={{ display: "flex", justifyContent: "center" }}
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <Image
                  src={`/media/pics/alphabet/${letterData.data.image}`}
                  style={{ width: "100%", height: "auto" }}
                />
              </Col>
              <Col
                style={{ display: "flex", justifyContent: "center" }}
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <Image
                  src={`/media/pics/alphabet/${letterData.data.image_desc}`}
                  style={{ width: "100%", height: "auto" }}
                />
              </Col>
            </Row>

            <Row className="my-3" gutter={[16, 16]}>
              {letterData && letterData.data.words ? (
                letterData.data.words.map((word) => (
                  <Col
                    key={word.id}
                    className="card-col"
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                  >
                    {word.refered_back != null ? (
                      <Link
                        to={`/category/${word.refered_back_obj.category_id}/words/${word.refered_back_obj.id}`}
                      >
                        <CustomCountriesCard
                          catName="Ayan"
                          id={word.id}
                          content={`${word.word_name} [${word.refered_back_obj.refered_back_name}]`}
                        />
                      </Link>
                    ) : word.word_name.endsWith("daktil") ? (
                      <CustomCountriesCard
                        catName="Ayan"
                        id={word.id}
                        content={word.word_name}
                      />
                    ) : (
                      <Link to={`/alphabet/${letter}/${word.id}`}>
                        <CustomCountriesCard
                          catName="Ayan"
                          id={word.id}
                          content={`${word.word_name} `}
                        />
                      </Link>
                    )}
                  </Col>
                ))
              ) : (
                // Render fallback content when letterData or letterData.words is undefined
                <p>No words available</p>
              )}
            </Row>

            <div className="d-flex justify-content-center mt-4">
              <Pagination
               style={{ marginTop: "10px" }}
               current={currentPage}
               defaultCurrent={1}
               total={letterData.pagination.total_items} // This should represent the total number of items, not total pages
               pageSize={20} // This should represent the number of items per page
               showLessItems={true} 
               onChange={handlePageChange}
               onPageChange={handlePageChange}
              />
             
            </div>
          </div>
        )}
      </Flex>

     </div>
     
    </>
  );
};

export default UniqueLetterComponent;
