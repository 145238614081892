import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Col, Row } from "antd";
import "./home.css";

const AboutSlider = () => {
  const images = [
    "/media/pics/demo-1.jpg",
    "/media/pics/demo-2.jpg",
    "/media/pics/demo-3.jpg",
    "/media/pics/demo-4.jpg",
    "/media/pics/demo-5.jpg",
  ];

  return (
    <>
 
  
    <div className="container-for-all">
    <Row className="body-margin padding-for-sections about-us-container d-flex justify-content-around">
        <Col className="about-us" 
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 10, offset: 0 }}
              xl={{ span: 10, offset: 0 }}>
          <h1>Jest Dili</h1>
          <h3>Layihə haqqında</h3>
          <p style={{fontSize:"17px"}}>
          Lüğət 3500-ə yaxın ümumişlək söz, 500 ifadə həmçinin  hər bir sözün və ifadənin jestindən ibarətdir. Jestlər video, foto və yazılı formada təqdim edilib. Lüğətin elmi-metodik cəhətdən düzgünlüyünün təmin edilməsi və layihənin keyfiyyətlə yerinə yetirilərək cəmiyyət üçün faydalı nəticələrin ortaya qoyula bilməsi məqsədilə lüğətin hazırlanması prosesinə bu sahədə təcrübəyə və ya mütəxəssislərə malik maraqlı qurumları cəlb etməklə, onların nümayəndələrindən ibarət “İşçi qrup” yaradılıb.
            </p>
        
        </Col>
        <Col className="about-us-slider d-flex align-items-center "
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 10, offset: 0 }}
              xl={{ span: 10, offset: 0 }}>
          <Carousel
            className="fitness_card_background_desktop"
            autoPlay={true}
            infiniteLoop={true}
            interval="3000"
            showThumbs={false}
            showStatus={false}
            // showIndicators={false}
            swipeable={false}
          >
          
            <div>
              <div class="about-us-card">
                <img
                  src="/media/pics/about/1.jpg"
                  alt="Fitness Trainer"
                />
              </div>
            </div>
            <div>
              <div class="about-us-card">
                <img
                  src="/media/pics/about/2.jpg"
                  alt="Fitness Trainer"
                />
              </div>
            </div>
            <div>
              <div class="about-us-card">
                <img
                  src="/media/pics/about/3.jpg"
                  alt="Fitness Trainer"
                />
              </div>
            </div>
            <div>
              <div class="about-us-card">
                <img
                  src="/media/pics/about/4.jpg"
                  alt="Fitness Trainer"
                />
              </div>
            </div>
            <div>
              <div class="about-us-card">
                <img
                  src="/media/pics/about/5.jpg"
                  alt="Fitness Trainer"
                />
              </div>
            </div>
          </Carousel>
        </Col>
      </Row>
      </div>
    
    </>
  );
};

export default AboutSlider;

