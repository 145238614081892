import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Breadcramb from "../breadcramb";
import { Menu, Dropdown } from "antd";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./unique-sentence.css";
import { useRef } from "react";
import { faPlay, faPause, faCompress } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "antd";

const UniqueSentenceComponent = ({ sentenceId }) => {
  const { pathname } = useLocation();
  const urlSentence = `https://morning-plains-82582-f0e7c891044c.herokuapp.com/sentences/${sentenceId}`;
  const urlLearnLaterSentences =
    `https://morning-plains-82582-f0e7c891044c.herokuapp.com/learnLaterSentences/${sentenceId}?check`;


  const [isLoading, setIsLoading] = useState(true);
  const [sentenceData, setSentenceData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [accessTokenValue, setAccessTokenValue] = useState(null);
  const token = localStorage.getItem("user-info");
  //fetch category datas
  useEffect(() => {
  
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // isLoading(true);
        const response = await fetch(urlSentence, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          setSentenceData(responseData);
          setIsPlaying(false);

          if (token) {
            const tokenObject = JSON.parse(token);
            const accessTokenValue = tokenObject.access_token;
            setAccessTokenValue(accessTokenValue);
            const response = await fetch(urlLearnLaterSentences, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessTokenValue}`,
              },
            });

            if (response.ok) {
              const data = await response.json();
              if (data.isLiked==true) {
                setToggle(true);
              } else {
                setToggle(false);
                console.log("yoxdu bazada");
              }
            } else {
              console.error("API error:", response.statusText);
            }
          }
        } else {
          console.error("API error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [urlSentence]);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showSpeedOptions, setShowSpeedOptions] = useState(false);
  const [speed, setSpeed] = useState(1);
  const videoRef = useRef(null);


  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);

      video.play().catch(error => {
        // Handle potential error when attempting to play
        console.error('Failed to play video:', error);
      });
    } else {
      video.pause();
      setIsPlaying(false);
    }
    // setIsPlaying(!video.paused);
  };
  const items = [
    {
      submenu: [
        {
          key: "speed-025x",
          label: "0.25x",
          onClick: () => handleSpeedChange(0.25),
        },
        {
          key: "speed-050x",
          label: "0.5x",
          onClick: () => handleSpeedChange(0.5),
        },
        {
          key: "speed-075x",
          label: "0.75x",
          onClick: () => handleSpeedChange(0.75),
        },
        {
          key: "speed-100x",
          label: "1x",
          onClick: () => handleSpeedChange(1),
        },
      ],
    },
  ];

  const handleSpeedChange = (speed) => {
    setSpeed(speed);
    videoRef.current.playbackRate = speed;
    setShowSpeedOptions(false);
    console.log(`Speed changed to ${speed}x`);
  };

  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const video = videoRef.current;

    // Add an event listener to detect fullscreen changes
    const handleFullscreenChange = () => {
      setIsFullscreen(
        !!(document.fullscreenElement || document.webkitFullscreenElement)
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);

  const handleExpand = () => {
    const video = videoRef.current;

    if (!isFullscreen) {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };
  // const accessTokenValue = tokenObject.access_token;
  const handleToggle = () => {
    setToggle((prevToggle) => !prevToggle);

    if (!toggle || toggle) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenValue}`, // Replace 'yourToken' with your actual token
        },
      };
      fetch(
        `https://morning-plains-82582-f0e7c891044c.herokuapp.com/learnLaterSentenceToggle/${sentenceData.current_sentence.id}`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          } else {
            console.log("isledi");
          }
        })
        .catch((error) => {
          console.error(
            "There was a problem with your fetch operation:",
            error
          );
        });
    }
  };

  return (
    <>
      <div className="container-for-all">
      <div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100vh",
            }}
          >
            <div className="three-body">
              <div className="three-body__dot"></div>
              <div className="three-body__dot"></div>
              <div className="three-body__dot"></div>
            </div>
          </div>
        ) : (
          <div>
            {pathname.includes("/category") ? (
              <Breadcramb topCategoryName="All Categories" />
            ) : pathname.includes("/cumleler") ? (
              <Breadcramb
                topCategoryName="Cümlələr"
                wordId={sentenceData.current_sentence.id}
                wordName={sentenceData.current_sentence.text}
                isSentence="true"

                // categoryName={categoryData.category_name}
              />

            ) : pathname.includes("/alphabet") ? (
              <Breadcramb topCategoryName="Alphabet" />
            ) : (
              // Render content when the path does not include "/category", "/sentence", or "/alphabet"
              <div>
                <h1>Not a Category, Sentence, or Alphabet Page</h1>
                {/* Other content specific to when the path does not include "/category", "/sentence", or "/alphabet" */}
              </div>
            )}

            <div className="body-margin">
              <Row className="py-4" gutter={[16, 16]}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 16 }}
                >
                  <div className="video-components">
                    <div
                      className={`d-flex justify-content-center custom-video-player ${
                        isHovered ? "hovered" : ""
                      }`}
                   
                      onClick={togglePlayPause}
                      style={{  minHeight:"400px" }}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}

                    >
                      <video
                       
                        ref={videoRef}
                        width="100%"
                        height="100%"
                        className="video-word"
                        loop
                        // autoPlay
                        // // onEnded={() => setIsPlaying(false)}
                        src={sentenceData.current_sentence.sign_lang_video}
                        // src={"https://jest-dili.s3.eu-north-1.amazonaws.com/Nar+C%C3%BCml%C9%99l%C9%99r/1+h%C9%99ft%C9%99+sonra+T%C3%BCrkiy%C9%99y%C9%99+ged%C9%99c%C9%99m+(LOGO).mp4"}
                      >
                        <source
                        // src={"https://jest-dili.s3.eu-north-1.amazonaws.com/Nar+C%C3%BCml%C9%99l%C9%99r/1+h%C9%99ft%C9%99+sonra+T%C3%BCrkiy%C9%99y%C9%99+ged%C9%99c%C9%99m+(LOGO).mp4"}

                          src={sentenceData.current_sentence.sign_lang_video}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>

                      <div className={`controls ${isPlaying ? "playing" : ""}`}>
                        {/* Switched positions of the buttons
        <button className="replay-button toggleButton" onClick={handleReplay}>
          <FontAwesomeIcon icon={faUndo} />
        </button> */}
                        <button className="togglePlayPause toggleButton">
                          <FontAwesomeIcon
                            icon={isPlaying ? faPause : faPlay}
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-start align-items-center "
                    style={{ padding: "0.5rem 40px" }}
                  >
                    <div className="d-flex justify-content-start align-items-center ">
                      <Dropdown
                        className="px-2"
                        overlay={
                          <Menu>
                            {items.map((item) => (
                              <Menu.Item key={item.key}>
                                {item.label}
                                <Menu
                                  placement="bottomLeft"
                                  style={{
                                    fontSize: "12px",
                                    minWidth: "100px",
                                  }}
                                >
                                  {item.submenu.map((subItem) => (
                                    <Menu.Item
                                      key={subItem.key}
                                      onClick={subItem.onClick}
                                    >
                                      {subItem.label}
                                    </Menu.Item>
                                  ))}
                                </Menu>
                              </Menu.Item>
                            ))}
                          </Menu>
                        }
                        placement="bottomLeft"
                        arrow={{
                          pointAtCenter: true,
                        }}
                      >
                        {/* Remove FontAwesomeIcon and replace with img tag */}
                        <img
                          src="/rush.png" // Specify the source of the image
                          alt="Tachometer" // Provide alternative text for accessibility
                          style={{
                            width: "60px", // Adjust width and height as needed
                            height: "40px",
                            cursor: "pointer",
                            color: "#4d63b8",
                          }}
                        />
                      </Dropdown>
                      <FontAwesomeIcon
                        onClick={handleExpand}
                        style={{ cursor: "pointer" }}
                        className="px-2 "
                        icon={isFullscreen ? faCompress : faExpand}
                        size="2x"
                      />
                    </div>
                    <div className="ms-3">
                      {token && ( // Check if token exists
                        <img
                          onClick={handleToggle}
                          // onClick={() => setToggle(!toggle)}
                          src={toggle ? "/media/pics/icons/toggled-heart.png" : "/media/pics/icons/heart.png"}
                          width="30"
                          height="30"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </div>
                </Col>
                <Col
                  className="d-flex flex-column justify-content-between"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 8 }}
                >
                  <div className="sentence-title-div"
                    style={{
                    
                      backgroundColor: " rgba(206, 178, 252, 0.48)",
                    }}
                  >
                    <div className="sentence-title">
                      <h4
                        className="font sentence-title-name"
                        style={{ marginBottom: "8px" }}
                      >
                        {sentenceData.current_sentence.text}
                      </h4>
                    </div>
                    {/* <div className="sentence-desc">
                      <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Quasi voluptatum totam nemo,{" "}
                      </p>
                    </div> */}
                  </div>
                  <div
                    style={{ padding: "10px 0px" }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="tooltip-container px-2 mx-2">
                        {sentenceData.prev_sentence ? (
                          <>
                            <span className="tooltip">
                              {sentenceData.prev_sentence.text}
                            </span>
                            <span className="text">
                              <Link
                                to={`/cumleler/${sentenceData.prev_sentence.id}`}
                                // onClick={() => window.location.reload()}

                              >
                                <LeftOutlined
                                  className="px-2 prev-sentence-button"
                                  style={{ fontSize: "35px", color: "black" }}
                                />
                              </Link>
                            </span>
                          </>
                        ) : (
                          <span className="text">
                            <LeftOutlined
                              className="px-2 prev-sentence-button"
                              style={{
                                fontSize: "35px",
                                color: "black",
                                opacity: 0.5,
                              }}
                            />
                          </span>
                        )}
                      </div>
                      <div className="tooltip-container px-2 mx-2">
                        {sentenceData.next_sentence ? (
                          <>
                            <span className="tooltip1">
                              {sentenceData.next_sentence.text}
                            </span>
                            <span className="text">
                              <Link
                                to={`/cumleler/${sentenceData.next_sentence.id}`}
                                // onClick={() => window.location.reload()}
                              >
                                <RightOutlined
                                  className="px-2 next-sentence-button"
                                  style={{ fontSize: "35px", color: "black" }}
                                />
                              </Link>
                            </span>
                          </>
                        ) : (
                          <span className="text disabled">
                            <RightOutlined
                              className="px-2 next-sentence-button"
                              style={{ fontSize: "35px", color: "black",  opacity: 0.5, }}
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>

      </div>

    
    
    </>
  );
};

export default UniqueSentenceComponent;
