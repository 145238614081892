import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home';
import DictionaryAlphabet from './pages/dictionaryAlphabet';
import Blog from './pages/blog';
import LogIn from './pages/login';
import SignUp from './pages/signUp';
import UniquePage from './pages/uniquePage';
import UniqueLetter from './pages/uniqueLetter';
import Faq from './pages/faq';
// import sidebar from 'C:\Users\Lenovo\Desktop\sign-language-fe -frontend\src\components\AdminPanel\components\sidebar.tsx'
import Contact from './pages/contact';
import CategoryWords from './pages/categoryWords';
import Profile from './pages/profile';
import UniqueLetterWord from './pages/uniqueLetterWord';
import UniqueSentence from './pages/uniqueSentence';
import ErrorPage from './pages/errorPage';
import UniqueBlog from './components/Blog/uniqueBlog';

function App(){
  return(
    <div>
      
      <BrowserRouter>
      <Routes>

        <Route  element={<Home/>} path="/home"/>
        <Route index element={<Home/>} path="/"/>

        <Route index element={<Home/>} path="/home#about-section"/>
        <Route path="/dictionary/:tabName?" element={<DictionaryAlphabet/>}/>
        <Route path="/blog" element={<Blog/>}/>
        <Route path="/login" element={<LogIn/>}/>
        <Route path="/signup" element={<SignUp/>}/>
        <Route path="/faq" element={<Faq/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/profile" element={<Profile/>}/>
        <Route path="/alphabet/:letter" element={<UniqueLetter/>}/>
        <Route path="/cumleler/:sentenceId" element={<UniqueSentence/>}/>
        <Route path="/category/:categoryId/words" element={<CategoryWords/>}/>
        <Route path="/category/:categoryId/words/:wordId" element={<UniquePage/>}/>
        <Route path="/alphabet/:letter" element={<UniqueLetter/>}/>
        <Route path="/alphabet/:letter/:wordId" element={<UniqueLetterWord/>}/>
        <Route path="/cumleler/:sentenceId" element={<UniquePage/>}/>
        <Route path="*" element={<ErrorPage />} />
        <Route path="/blog/:id" element={< UniqueBlog/>} />


      
      </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;

