import React from "react";
import Layout from "../components/Layout/layout";
import RightSideBlog from "../components/Blog/rightSideBlog.js";
import LeftSideBlog from "../components/Blog/leftSideBlog.js";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
const Blog = () => {
  return (
    <Layout>
         <div className="container-for-all">
         <div className="body-margin">
      <MDBRow>
        {/* <MDBCol sm="12" md="4" lg="4" xl="4">
          <LeftSideBlog></LeftSideBlog>
        </MDBCol> */}
        <MDBCol sm="12" md="12" lg="12" xl="12">
          <RightSideBlog></RightSideBlog>
        </MDBCol>
      </MDBRow>
      </div>

         </div>
     
     
    </Layout>
  );
};

export default Blog;
