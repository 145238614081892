import React from "react";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
} from "mdb-react-ui-kit";

const Breadcramb = ({
  isSentence,
  topcategoryId,
  topCategoryName,
  categoryId,
  categoryName,
  wordId,
  wordName,
}) => {
  return (
    <div>
    <MDBNavbar expand="lg" light bgColor="light">
      <MDBContainer fluid>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <a
              style={{ fontSize: "16px" }}
              href={`/dictionary/${topCategoryName}`}
            >
              {topCategoryName}
            </a>
          </MDBBreadcrumbItem>
          {categoryId == null ? (
            <MDBBreadcrumbItem>
              <a
                style={{ fontSize: "16px" }}
                href={`/alphabet/${categoryName}`}
              >
                {categoryName}
              </a>
            </MDBBreadcrumbItem>
          ) : (
            <MDBBreadcrumbItem>
              <a
                style={{ fontSize: "16px" }}
                href={`/category/${categoryId}/words`}
              >
                {categoryName}
              </a>
            </MDBBreadcrumbItem>
          )}
          {wordId != null && wordName != null && categoryId != null ? (
            <MDBBreadcrumbItem active>
              <a
                style={{ fontSize: "16px", fontWeight: "bold" }}
                href={`/category/${categoryId}/words/${wordId}`}
              >
                {wordName}
              </a>
            </MDBBreadcrumbItem>
          ) : (
            <MDBBreadcrumbItem active>
              {isSentence == null ? (
                <a
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                  href={`/əlifba/${categoryName}/${wordId}`}
                >
                  {wordName}
                </a>
              ) : (
                <a
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                  href={`/cumleler/${wordId}`}
                >
                  {wordName}
                </a>
              )}
            </MDBBreadcrumbItem>
          )}
        </MDBBreadcrumb>
      </MDBContainer>
    </MDBNavbar>
  </div>
  
  );
};

export default Breadcramb;
