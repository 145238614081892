import React, { useState, useEffect, createRef, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart as farHeart,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import { Col, Row, Button, Modal, Tabs, Card, Pagination } from "antd";
import axios from "axios";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { log } from "util";

const { TabPane } = Tabs;

const FavBox = () => {
  const CustomCountriesCard = ({ content }) => (
    <Card
      className="countries-custom-card"
      content={content}
      style={{ backgroundColor: "#F0E7FF !important" }}
    >
      <p style={{ color: "black" }}>{content}</p>
    </Card>
  );

  const [accessTokenValue, setAccessTokenValue] = useState("");
  const [favoriteWords, setFavoriteWords] = useState([]);
  const [favoriteSentences, setFavoriteSentences] = useState([]);
  const [token, setToken] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteItemType, setDeleteItemType] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [currentPageW, setCurrentPageW] = useState(1);
  const [currentPageS, setCurrentPageS] = useState(1);

  const [totalNumberOfPagesW, setTotalNumberOfPagesW] = useState(1);
  const [totalNumberOfPagesS, setTotalNumberOfPagesS] = useState(1);

  const containerStyle = {
    // maxHeight: "500px",
    overflowY: "auto",
  };
  const showDeleteModal = (itemId, itemType) => {
    setDeleteItemId(itemId);
    setDeleteItemType(itemType);
    setIsDeleteModalVisible(true);
  };

  const deleteItem = async () => {
    try {
      if (deleteItemType === "word") {
        await axios.post(
          `https://morning-plains-82582-f0e7c891044c.herokuapp.com/learnLaterWordToggle/${deleteItemId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else if (deleteItemType === "sentence") {
        await axios.post(
          `https://morning-plains-82582-f0e7c891044c.herokuapp.com/learnLaterSentenceToggle/${deleteItemId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      // Update the local state to remove the deleted item
      if (deleteItemType === "word") {
        setFavoriteWords((prevWords) =>
          prevWords.filter((word) => word.id !== deleteItemId)
        );
      } else if (deleteItemType === "sentence") {
        setFavoriteSentences((prevSentences) =>
          prevSentences.filter((sentence) => sentence.id !== deleteItemId)
        );
      }

      setIsDeleteModalVisible(false); // Close the modal after deletion
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const fetchFavoriteWords = async (page1, page2) => {
    const token = localStorage.getItem("user-info");
    const tokenObject = JSON.parse(token);
    const accessTokenValue = tokenObject.access_token;
    setToken(accessTokenValue);

    try {
      const wordsResponse = await axios.get(
        `https://morning-plains-82582-f0e7c891044c.herokuapp.com/learnLaterWords?page=${page1}`,
        {
          headers: {
            Authorization: `Bearer ${accessTokenValue}`,
          },
        }
      );

      console.log("Words Response:", wordsResponse);

      const sentencesResponse = await axios.get(
        `https://morning-plains-82582-f0e7c891044c.herokuapp.com/learnLaterSentences?page=${page1}`,
        {
          headers: {
            Authorization: `Bearer ${accessTokenValue}`,
          },
        }
      );
      console.log(wordsResponse);
      console.log(wordsResponse);
      // Check if there are liked words in the response
      if (wordsResponse.data.liked_words.length > 0) {
        setFavoriteWords(wordsResponse.data.liked_words);
      } else {
        setFavoriteWords("");
      }
      // setFavoriteWords(wordsResponse.data.liked_words);
      // setTotalNumberOfPagesW(wordsResponse.pagination.total_items);
      // setCurrentPageW(wordsResponse.pagination.page);

      console.log(favoriteWords);

      // setFavoriteSentences(sentencesResponse.data.liked_sentences);
      if (sentencesResponse.data.liked_sentences.length > 0) {
        setFavoriteSentences(sentencesResponse.data.liked_sentences);
      } else {
        setFavoriteSentences("");
      }
      console.log(favoriteWords);
      console.log(favoriteSentences);

      //  setTotalNumberOfPagesS(sentencesResponse.pagination.total_items);
      // setCurrentPageS(sentencesResponse.pagination.page);
    } catch (error) {
      console.error("Error fetching favorite words/sentences:", error);
    }
  };
  const handlePageChange = (page) => {
    // fetchFavoriteWords(page);
  };

  useEffect(() => {
    fetchFavoriteWords();
  }, []);

  useEffect(() => {
    // Save favoriteWords and favoriteSentences to local storage
    localStorage.setItem("favoriteWords", JSON.stringify(favoriteWords));
    localStorage.setItem(
      "favoriteSentences",
      JSON.stringify(favoriteSentences)
    );
  }, [favoriteWords, favoriteSentences]);

  useEffect(() => {
    const storedFavoriteWords = localStorage.getItem("favoriteWords");
    const storedFavoriteSentences = localStorage.getItem("favoriteSentences");

    if (storedFavoriteWords) {
      setFavoriteWords(JSON.parse(storedFavoriteWords));
    }

    if (storedFavoriteSentences) {
      setFavoriteSentences(JSON.parse(storedFavoriteSentences));
    }
  }, []);

  const columns = [
    {
      title: "No",
      dataIndex: "wordNumber",
      key: "wordNumber",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Söz",
      dataIndex: "word_name",
      key: "word_name",
      render: (text, record) => (
        <Link to={`/category/${record.category_id}/words/${record.id}`}>
          {text}
        </Link>
      ),
    },
    // {
    //   title: 'Əməliyyatlar',
    //   key: 'action',
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Button type="text" danger icon={<DeleteOutlined />} />
    //     </Space>
    //   ),
    // },
  ];

  const columns2 = [
    {
      title: "No",
      dataIndex: "wordNumber",
      key: "wordNumber",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Cümlə",
      dataIndex: "text",
      key: "text",
      render: (text, record) => (
        <Link to={`/sentences/${record.id}`}>{text}</Link>
      ),
    },
    // {
    //   title: 'Əməliyyatlar',
    //   key: 'action',
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Button type="text" danger icon={<DeleteOutlined />} />
    //     </Space>
    //   ),
    // }
  ];
  const renderWordCard = (record) => (
    <Card
      key={record.id}
      title={`Söz ${record.wordNumber}`}
      extra={
        <div>
          <Link to={`/category/${record.category_id}/words/${record.id}`}>
            Details
          </Link>
          <Button
            type="link"
            icon={<FontAwesomeIcon icon={faTrashAlt} />}
            onClick={() => showDeleteModal(record.id, "word")}
          />
        </div>
      }
      // style={{ margin: "10px 0" }}
    >
      {record.word_name}
    </Card>
  );

  const renderSentenceCard = (record) => (
    <Card
      key={record.id}
      title={`Cümlə ${record.wordNumber}`}
      extra={
        <div>
          <Link to={`/sentences/${record.id}`}>Details</Link>
          <Button
            type="link"
            icon={<FontAwesomeIcon icon={faTrashAlt} />}
            onClick={() => showDeleteModal(record.id, "sentence")}
          />
        </div>
      }
      // // style={{ margin: "10px 0" }}
    >
      {record.text}
    </Card>
  );
  return (
    <div className="fav-container" style={containerStyle}>
      {!favoriteWords.length && !favoriteSentences.length ? (
        <Col
          className="first-frame-empty-favorites"
          xs={24}
          md={24}
          lg={24}
          xl={24}
        >
          <p className="fav-edit-details">
            <FontAwesomeIcon icon={farHeart} />
            Ən Sevdiyim Sözlər və Cümlələr
          </p>
          <h4 className="fav-profile-heading">
            Hələ sevimli söz və ya cümlələriniz yoxdur.{" "}
          </h4>
        </Col>
      ) : (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Sevimli Sözlər" key="1">
            {/* <Table columns={columns} dataSource={favoriteWords} />; */}
            <Row
              className="fav-first-frame-all justify-content-center"
              style={{ maxHeight: "400px" }}
              gutter={16}
            >
              {favoriteWords.length > 0 ? (
                <>
                  {favoriteWords.map((word) => (
                    <Link to={`/category/${word.category_id}/words/${word.id}`}>
                      <Col xs={24} lg={12} className="mt-2">
                        <CustomCountriesCard
                          content={word.word_name}
                        ></CustomCountriesCard>
                      </Col>
                    </Link>
                  ))}
                </>
              ) : (
                <Col
                  className="first-frame-empty-favorites"
                  xs={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  <p className="fav-edit-details">
                    <FontAwesomeIcon icon={farHeart} />
                    Ən Sevdiyim Sözlər
                  </p>
                  <h4 className="fav-profile-heading">
                    Hələ sevimli sözləriniz yoxdur.{" "}
                  </h4>
                </Col>
              )}
            </Row>
            {/* <div className="d-flex justify-content-center mt-4">
                  <Pagination
                    style={{ marginTop: "10px" }}
                    current={currentPageW}
                    defaultCurrent={1}
                    total={totalNumberOfPagesW}
                    pageSize={4} 
                     showLessItems={true} 
                    onChange={handlePageChange}

              
                  />
                </div> */}
          </TabPane>
          <TabPane tab="Sevimli Cümlələr" key="2">
            {/* <Table columns={columns2} dataSource={favoriteSentences} />; */}
            <Row
              className="fav-first-frame-all  justify-content-center "
              style={{ maxHeight: "400px" }}
              gutter={16}
            >
              {favoriteSentences.length > 0 ? (
                <>
                  {favoriteSentences.map((sentence) => (
                    <Link to={`/sentences/${sentence.id}`}>
                      <Col xs={24} lg={12} className="mt-2">
                        <CustomCountriesCard
                          content={sentence.text}
                        ></CustomCountriesCard>
                      </Col>
                    </Link>
                  ))}
                </>
              ) : (
                <Col
                  className="first-frame-empty-favorites"
                  xs={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  <p className="fav-edit-details">
                    <FontAwesomeIcon icon={farHeart} />
                    Ən Sevdiyim Cümlələr
                  </p>
                  <h4 className="fav-profile-heading">
                    Hələ sevimli cümlələriniz yoxdur.{" "}
                  </h4>
                </Col>
              )}
            </Row>
          </TabPane>
        </Tabs>
        // <div></div>
      )}

      {/* Delete Confirmation Modal */}
      <Modal
        title="Silməyi təsdiqləyin"
        visible={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        onOk={deleteItem}
        okText="Sil"
        cancelText="Ləğv et"
      >
        <p>Bu elementi silmək istədiyinizə əminsiniz?</p>
      </Modal>
      <div className="d-block justify-content-center "></div>
    </div>
  );
};

export default FavBox;
