import React , {useState} from "react";
import { Input } from "antd";
import { Card } from "antd";
import "./leftSide.css";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import {  List } from "antd";
import axios from "axios";
import debounce from "lodash.debounce";
const { Search } = Input;

const LeftSideBlog = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  let searchTimeout;

  const onSearch = async (value) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://morning-plains-82582-f0e7c891044c.herokuapp.com/words/search?q=${value}`
      );

      if (response.data==0) {
        setLoading(false);


        return;
      }
      // Limit the results to the top 6
      const topResults = response.data.slice(0, 6);

      setSearchResults(topResults);

    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = debounce(onSearch, 300);

  const handleInputChange = (e) => {
    const { value } = e.target;

    if (document.activeElement === e.target) {
      const { value } = e.target;
      debouncedSearch(value);
    }else{
      setLoading(false);
    }

  };


  return (
    <div className="body-margin">
      <MDBRow className="justify-content-center">
      <MDBCol md="12" className="blog-left-elements d-block">
        <Search
          placeholder="Input search text"
          onSearch={onSearch}
          onChange={handleInputChange}
          style={{
            width: 300,
            padding: "20px 0px",
          }}
        />
       
      </MDBCol>
      <MDBCol md="12" className="blog-left-elements">
  <List
    loading={loading}
    dataSource={searchResults}
    renderItem={(item) => (
      item.refered_back != null ? (
        <List.Item>
          <Link to={`/category/${item.refered_back_obj.refered_back_category_id}/words/${item.refered_back}`} className="search-result-link">
            {item.word_name}
          </Link>
        </List.Item>
      ) : item.word_name.endsWith("daktil") ? (
        <List.Item>
          <Link disabled className="search-result-link">
            {item.word_name}
          </Link>
        </List.Item>

        // <CustomCountriesCard
        //   catName="Ayan"
        //   id={word.id}
        //   content={word.word_name}
        //   disabled
        // />
      ) : (
          
          <List.Item>
          <Link to={`/category/${item.category_id}/words/${item.id}`} className="search-result-link">
            {item.word_name}
          </Link>
        </List.Item>
          
      )
    )}
  />
</MDBCol>

       
      </MDBRow>
    </div>
  );
};

export default LeftSideBlog;
{/* <MDBCol md="12" className="blog-left-elements">
<Card
  className="left-side-cards"
  title="Top Posts"
  bordered={false}
  style={{
    width: 300,
  }}
>
  <div className="left-side-bottom-content">
    <ul>
      <li>
        {" "}
        <Link></Link>
      </li>

      <li>
        <Link>
          <div className="d-flex ">
            <div className="left-icon-posts">
              <img
                width="50"
                height="50"
                src="/logo.jpg"
                alt="logo"
              />
            </div>
            <div className="right-icon-posts">
              <p className="title-right-post">Lorem Ipsum</p>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing
                elit.
              </p>
              <p>Recent Updates 2024</p>
            </div>
          </div>
        </Link>
      </li>

      <li>
        <Link>
          <div className="d-flex ">
            <div className="left-icon-posts">
              <img
                width="50"
                height="50"
                src="/logo.jpg"
                alt="logo"
              />
            </div>
            <div className="right-icon-posts">
              <p className="title-right-post">Lorem Ipsum</p>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing
                elit.
              </p>

              <p>Recent Updates 2024</p>
            </div>
          </div>
        </Link>
      </li>

      <li>
        <Link>
          <div className="d-flex ">
            <div className="left-icon-posts">
              <img
                width="50"
                height="50"
                src="/logo.jpg"
                alt="logo"
              />
            </div>
            <div className="right-icon-posts">
              <p className="title-right-post">Lorem Ipsum</p>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing
                elit.
              </p>

              <p>Recent Updates 2024</p>
            </div>
          </div>
        </Link>
      </li>

      <li>
        <Link>
          <div className="d-flex ">
            <div className="left-icon-posts">
              <img
                width="50"
                height="50"
                src="/logo.jpg"
                alt="logo"
              />
            </div>
            <div className="right-icon-posts">
              <p className="title-right-post">Lorem Ipsum</p>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing
                elit.
              </p>

              <p>Recent Updates 2024</p>
            </div>
          </div>
        </Link>
      </li>
    </ul>
  </div>
</Card>
</MDBCol> */}