import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./meetTeamSlider.css";

const MeetTeamSlider = () => {

  function CustomSlide(props) {
    const { index, ...otherProps } = props;
    return (
      <div {...otherProps}>
        <h3>{index}</h3>
      </div>
    );
  }
  
  var settings = {
      centerPadding: "100px",
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 677,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,

          }
        }
      ]
  };

  const [slides] = useState([
    {
      fullName: "Kamran Abasov",
      position: "“Karlara dəstək” İctimai Birliyinin Sədri, Proyekt rəhbəri",
      description:
        "Layihə rəhbəri, jest dili üzrə mütəxəsis, jest dili üzrə ekspert",
      imageUrl: "/media/pics/team-trim/KamranAbasov.jpg",
    },
    {
      fullName: "Rəhimli Heydər",
      position: "“Karlara dəstək” İctimai Birliyinin Sədr Müavini",
      description:
        "Layihə koordinatoru, jest dili üzrə mütəxəsis, surdo-tərcüməçi",
      imageUrl: "/media/pics/team-trim/HeydərRəhimli.jpg",
    },
    {
      fullName: "Samir Sadıqov",
      position: "“Karlara dəstək” İctimai Birliyinin idarə heyyətinin üzvü",
      description:
        "Eşitmə mədudiyyətlidir. Layhiədə aktyor qismində çıxış etmişdir.",
      imageUrl: "/media/pics/team-trim/SamirSadıqov.jpg",
    },
    {
      fullName: "Şəmsi Şahbazova",
      description:
        "Eşitmə mədudiyyətlidir. Layhiədə aktyor qismində çıxış etmişdir.",
      imageUrl: "/media/pics/team-trim/1.jpg",
    },
    {
      fullName: "Cəmilə Paluch",
      description:
        "Eşitmə mədudiyyətlidir. Layhiədə aktyor qismində çıxış etmişdir.",
      imageUrl: "/media/pics/team-trim/6.jpg",
    },
    {
      fullName: "Zəhra Atakişiyeva",
      description:
        "Eşitmə mədudiyyətlidir. Layhiədə aktyor qismində çıxış etmişdir.",
      imageUrl: "/media/pics/team-trim/7.jpg",
    },
    {
      fullName: "Mahmud Səfərov",
      description:
        "Eşitmə mədudiyyətlidir. Layhiədə aktyor qismində çıxış etmişdir.",
      imageUrl: "/media/pics/team-trim/3.jpg",
    },
    {
      fullName: "Nigar Məmmədzadə",
      description:
        "Eşitmə mədudiyyətlidir. Layhiədə aktyor qismində çıxış etmişdir.",
      imageUrl: "/media/pics/team-trim/4.jpg",
    },
    {
      fullName: "Orxan Ələsgərov",
      description:
        "Eşitmə mədudiyyətlidir. Layhiədə aktyor qismində çıxış etmişdir.",
      imageUrl: "/media/pics/team-trim/5.jpg",
    },
    {
      fullName: "Sevarə Ələsgərova",
      description:
        "Eşitmə mədudiyyətlidir. Layhiədə aktyor qismində çıxış etmişdir.",
      imageUrl: "/media/pics/team-trim/2.jpg",
    },
    {
      fullName: "Nurəddin Sədili",
      position:
        "ADA Universitetinin Mühəndislik fakültəsinin müəllimi",
      description:
        "Veb-saytının yenidən dizaynı və hazırlanmasında iştirak etmişdir.",
      imageUrl: "https://jest-dili.s3.eu-north-1.amazonaws.com/homeImages/Nureddin.jpeg",
    },
    {
      fullName: "Amir Adamov",
      position: "ADA Universitetinin tələbəsi, Database Administrator",
      description:
        "Veb-saytının yenidən dizaynı və hazırlanmasında iştirak etmişdir.",
      imageUrl: "https://jest-dili.s3.eu-north-1.amazonaws.com/homeImages/amiradamov.jpg",
    },
    {
      fullName: "Orxan İsmayılov",
      position: "ADA Universitetinin tələbəsi, Backend üzrə proqramçı",
      description:
        "Veb-saytın yenidən dizaynı və hazırlanmasında iştirak etmişdir.",
      imageUrl: "https://jest-dili.s3.eu-north-1.amazonaws.com/homeImages/Orkhan.jpg",
    },
    {
      fullName: "Ayan Nəbizadə",
      position: "ADA Universitetinin tələbəsi, Frontend üzrə proqramçı",
      description:
        "Veb-saytın yenidən dizaynı və hazırlanmasında iştirak etmişdir.",
      imageUrl: "https://jest-dili.s3.eu-north-1.amazonaws.com/homeImages/chewpaca.jpg",
    },
    {
      fullName: "Nərgiz Bayramova",
      position:
        "ADA Universitetinin tələbəsi, Frontend üzrə proqramçı, UX/UI dizayner",
      description:
        "Veb-saytın yenidən dizaynı və hazırlanmasında iştirak etmişdir.",
      imageUrl: "https://jest-dili.s3.eu-north-1.amazonaws.com/homeImages/Nargiz-Bayramova.jpg",
    },
    {
      fullName: "Nərmin Mürşüdova",
      position: "ADA Universitetinin tələbəsi, Frontend üzrə proqramçı",
      description:
        "Veb-saytın yenidən dizaynı və hazırlanmasında iştirak etmişdir.",
      imageUrl: "https://jest-dili.s3.eu-north-1.amazonaws.com/homeImages/photo_5375183899619086981_y.jpg",
    },
  ]);
  return (
    <div className="container-for-all">
        <div className="body-margin padding-for-sections ">
      <div>
        <div className="home-title">
          <h3>KOMANDAMIZ</h3>
        </div>
      </div>
      
      <div className="">
        <Slider {...settings}>
          {slides.map((slideContent, index) => (
            <div key={index} className="komanda-card" >
              <div className="meet-pic-class">
                <img
                  src={slideContent.imageUrl}
                  alt=""
                  style={{
                    boxShadow:" rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    borderRadius: "10px",
                    width: "90%",
                    height: "270px",
                    objectFit: "cover",
                    marginTop: "25px",
                  }}
                />
              </div>
              <div>
                <div>
                  <p className="meet-team-position mb-1">{slideContent.position} <br /> <span className="meet-team-name ">{slideContent.fullName}</span></p>
                </div>
                <div className="meet-team-desc">
                  <hr className="hr" />
                  <p className="meet-team-description">
                    {slideContent.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>


      {/* <div>
        <div className="home-title mt-5">
          <h5 style={{fontWeight:"600", textAlign:"center", marginBottom:"26px"}}>“ <b>İşçi qrup</b> ”un tərəfi olan qurumlar və onların təyin etdikləri
            nümayəndələr:</h5>
          <p className="text-start">
            <b>Azərbaycan Milli Elmlər Akademiyası : </b><br />  
            <em> Gültəkin Əliyeva </em>- Nəsimi
            adına Dilçilik İnstitutunun Sosiolinqvistika və psixolinqvistika
            şöbəsinin aparıcı elmi işçisi, filologiya üzrə fəlsəfə doktoru,
            dosent;<br />
            <em> Zülfiyyə Musavi</em> - Nəsimi adına Dilçilik İnstitutunun
            Sosiolinqvistika və psixolinqvistika şöbəsinin böyük elmi işçisi
            <br />
           <b> Azərbaycan Respublikası Təhsil Nazirliyi</b> : <br /> 
         <em>  Həbib Kərimov</em> -
            De-İnstitutlaşma və Uşaqların Müdafiəsi İdarəsinin rəis müavini
            <br />
            <b>Azərbaycan Respublikası Əmək və Əhalinin Sosial Müdafiəsi Nazirliyi:</b>
            <br /> 
          <em>  Əfsun Səmədzadə</em> - Sosial sifarişlər və layihələr üzrə baş
            mütəxəssis
            <br />
          <b>  Azərbaycan Pedaqoji Universiteti :</b> <br /> 
         <em> Nailə Hüseynova</em> – “Xüsusi təhsil”
            kafedrasının müdiri,<br />
            <em> Dosent Tamilla Ağayeva</em> – “Xüsusi təhsil”
            kafedrasının dosenti,<br />
            <em> Adilə Mürsəlova </em>– “Xüsusi təhsil” kafedrasının
            müəllimi
          </p>
        </div>
      </div> */}
    </div>

      </div>
  
  );
};

export default MeetTeamSlider;