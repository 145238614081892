import React, { useState } from "react";
import "./footer.css";
import {
  MDBFooter,
  MDBBtn,
  MDBInput,
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

const Subscribe = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {

    e.preventDefault();
    try {
      const response = await fetch(
        "https://morning-plains-82582-f0e7c891044c.herokuapp.com/subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.ok) {
        console.log("Subscription successful!");
        setEmail("");
      } else {
        console.log(response);
        console.log(email);

        console.error("Failed to subscribe");
      }
    } catch (error) {
      console.error("Error subscribing:", error);
    }
  };

  return (
    <div className="container-for-all">
      <div className="body-margin padding-for-sections pb-5">
        <MDBFooter className="text-center text-lg-left">
          <form action="" onSubmit={handleSubmit}>
            <MDBRow>
              <MDBCol size="12" sm="12" md="6" lg="6" className="mb-4 mb-md-0">
                <h3>Saytda yeniliklər haqqında </h3>
                <h3>gündəlik bildiriş alın!</h3>
              </MDBCol>

              <MDBCol
                size="12"
                sm="12"
                md="6"
                lg="6"
                className="mb-4 mb-md-0 d-flex align-items-center "
              >
                <MDBRow>
                  <MDBCol
                    size="8"
                    sm="8"
                    md="8"
                    lg="8"
                    className="mb-4 mb-md-0"
                  >  <div >
                  <MDBInput
                    type="text"
                    id="form5Example2"
                    label="E-poçt ünvanınız"

                    value={email} // Pass the value prop with the value of email state
                    onChange={(e) => setEmail(e.target.value)} 
                    // style={{ width: "250px" }}
                  />
                </div>

                  </MDBCol>
                  <MDBCol
                    size="4"
                    sm="4"
                    md="4"
                    lg="4"
                    className="mb-4 mb-md-0"
                  >  <div >
                 
                <div>
                  <MDBBtn type="submit"   style={{ backgroundColor: "#2b2676" }}>Qoşul!</MDBBtn>
                </div>
                </div>

                  </MDBCol>
                </MDBRow>
              
              </MDBCol>
            </MDBRow>
          </form>
        </MDBFooter>
      </div>
    </div>
  );
};

export default Subscribe;
