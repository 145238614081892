import React from "react";
import "../dictionary-alphabet/alphabet.css";
import { Card } from "antd";
import { Flex, Pagination } from "antd";
import { Col, Row } from "antd";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Breadcramb from "../breadcramb";
// import { List } from "antd";
import { Input } from "antd";
// import axios from "axios";
import SearchComponent from "../dictionary-alphabet/seach-component";
// import { useRef } from "react";
// import debounce from "lodash.debounce";

// const { Search } = Input;

const CustomCountriesCard = ({ id, content, catName }) => (
  <Card
    className="countries-custom-card"
    id={id}
    content={content}
    catName={catName}
  >
    <p style={{ color: "black" }}>{content}</p>
  </Card>
);

const AllCategoryWords = ({ element }) => {
  const { pathname } = useLocation();

  const url = `https://morning-plains-82582-f0e7c891044c.herokuapp.com/category/${element}/words`;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const urlCategory = `https://morning-plains-82582-f0e7c891044c.herokuapp.com/category/${element}`;
  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(urlCategory, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          setCategoryData(responseData);
          console.log(categoryData);
        } else {
          console.error("API error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [url]);

  //words
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);

  const fetchData = async (page) => {
    try {
      const response = await fetch(`${url}?page=${page}&per_page=20`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

    
      if (response.ok) {
        const responseData = await response.json();
        setData(responseData);
        setTotalNumberOfPages(responseData.pagination.total_items);
        setCurrentPage(responseData.pagination.page);
      } else {
        console.error("API error:", response.statusText);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1); //  initial data
  }, []); // empty  array ensures the effect runs only once

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  let searchTimeout;

  
  return (
    <div>
       <div className="container-for-all">
       <Flex gap="small" vertical>
        <div>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
              }}
            >
              <div className="three-body">
                <div className="three-body__dot"></div>
                <div className="three-body__dot"></div>
                <div className="three-body__dot"></div>
              </div>
            </div>
          ) : (
            <div>
              {/*           
           <Breadcramb 

              categoryId={categoryData.id}
              categoryName={categoryData.category_name}
           
              
            /> */}
              {pathname.includes("/category") ? (
                <Breadcramb
                  topCategoryName="Bütün Kategoriyalar"
                  categoryId={categoryData.id}
                  categoryName={categoryData.category_name}
                />
              ) : pathname.includes("/sentence") ? (
                <Breadcramb
                  topCategoryName="Sentences"
                  categoryId={categoryData.id}
                  categoryName={categoryData.category_name}
                />
              ) : pathname.includes("/alphabet") ? (
                <Breadcramb
                  topCategoryName="Alphabet"
                  categoryId={categoryData.id}
                  categoryName={categoryData.category_name}
                />
              ) : (
                <div>
                  <h1>Not a Category, Sentence, or Alphabet Page</h1>
                </div>
              )}

              <div className="body-margin">
                <SearchComponent/>
             

                <Row className="my-3" gutter={[16, 16]} height="700px">
                  {data.words.map((word) => (
                    <Col
                      className="card-col"
                      key={word.id}
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 8 }}
                      lg={{ span: 6 }}
                    >
                      {word.refered_back != null ? (
                        <Link
                          to={`/category/${word.refered_back_obj.refered_back_category_id}/words/${word.refered_back}`}
                        >
                          <CustomCountriesCard
                            catName="Ayan"
                            id={word.id}
                            content={`${word.word_name} [${word.refered_back_obj.refered_back_name}]`}
                          />
                        </Link>
                      ) : word.word_name.endsWith("daktil") ? (
                        <CustomCountriesCard
                          catName="Ayan"
                          id={word.id}
                          content={word.word_name}
                          disabled
                        />
                      ) : (
                        <Link
                          to={`/category/${word.category_id}/words/${word.id}`}
                        >
                          <CustomCountriesCard
                            catName="Ayan"
                            id={word.id}
                            content={word.word_name}
                          />
                        </Link>
                      )}
                    </Col>
                  ))}
                </Row>
                <div className="d-flex justify-content-center mt-4">
                  <Pagination
                    style={{ marginTop: "10px" }}
                    current={currentPage}
                    defaultCurrent={1}
                    total={totalNumberOfPages}
                    pageSize={21} 
                     showLessItems={true} 
                    onChange={handlePageChange}

              
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Flex>
       </div>
   
    </div>
  );
};

export default AllCategoryWords;
