import React from "react";
import { Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
import "./home.css";

const style = {
  background: "#0092ff",
  padding: "8px 0",
};
const Colloborations = () => {
  return (
   
    <div className="container-for-all">
 <div className="body-margin padding-for-sections">
     
     <div className="home-title ">
         <h3>Əməkdaş Tərəflər</h3>
         <hr />
       </div>
 
       <Row justify="center" className="align-items-center">
       <Col xs={4} sm={4} md={4} lg={4} xl={4} className="responsive-col">
           <Link to="#">
             <img className="responsive-img" src="./media/pics/colloborations/b.jpg" alt="" />
           </Link>
         </Col>
        

        
        
       
         <Col xs={4} sm={4} md={4} lg={4} xl={4} className="responsive-col">
           <Link to="#">
             <img className="responsive-img" src="./media/pics/colloborations/newBirlik.png" alt="" />
           </Link>
         </Col>
         <Col xs={4} sm={4} md={4} lg={4} xl={4} className="responsive-col">
           <Link to="#">
             <img
               className="responsive-img"
               src="./media/pics/colloborations/ada-logo.jpg"
               alt=""
             />
           </Link>
         </Col>
        
         {/* <Col xs={4} sm={4} md={4} lg={4} xl={4} className="responsive-col">
           <Link to="#">
             <img className="responsive-img" src="./media/pics/colloborations/d.jpg" alt="" />
           </Link>
         </Col> */}
         <Col xs={4} sm={4} md={4} lg={4} xl={4} className="responsive-col">
           <Link to="#">
             <img
               className="responsive-img"
               src="./media/pics/colloborations/logo_riib.png"
               alt=""
               width="100%"
             />
           </Link>
         </Col>
      
        
       </Row>
     
     </div>
      </div>
   
  );
};

export default Colloborations;