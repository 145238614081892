import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout/layout';
import '../assets/errorPage.css'; // Import CSS file if needed


function ErrorPage() {
  const [selector3, setSelector3] = useState(0);
  const [selector2, setSelector2] = useState(0);
  const [selector1, setSelector1] = useState(0);

  const randomNum = () => Math.floor(Math.random() * 9) + 1;

  useEffect(() => {
    let loop1, loop2, loop3;
    let time = 30;
    let i = 0;

    loop3 = setInterval(() => {
      if (i > 40) {
        clearInterval(loop3);
        setSelector3(4);
      } else {
        setSelector3(randomNum());
        i++;
      }
    }, time);

    loop2 = setInterval(() => {
      if (i > 80) {
        clearInterval(loop2);
        setSelector2(0);
      } else {
        setSelector2(randomNum());
        i++;
      }
    }, time);

    loop1 = setInterval(() => {
      if (i > 100) {
        clearInterval(loop1);
        setSelector1(4);
      } else {
        setSelector1(randomNum());
        i++;
      }
    }, time);

    return () => {
      clearInterval(loop3);
      clearInterval(loop2);
      clearInterval(loop1);
    };
  }, []);

  return (
    <Layout>

        <div className="error-page ">
      <div className="container-floud">
        <div className="col-xs-12 ground-color text-center">
          <div className="container-error-404">
            <div className="clip"><div className="shadow"><span className="digit thirdDigit">{selector3}</span></div></div>
            <div className="clip"><div className="shadow"><span className="digit secondDigit">{selector2}</span></div></div>
            <div className="clip"><div className="shadow"><span className="digit firstDigit">{selector1}</span></div></div>
            {/* <div className="msg">OH!<span className="triangle"></span></div> */}
          </div>
          <h2 className="h1">Bağışlayın! Səhifə tapılmadı</h2>
        </div>
      </div>
    </div>

    </Layout>
    
  );
}

export default ErrorPage;
