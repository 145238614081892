

  import React, { useState } from "react";
import "./alphabet.css";

const CustomCard = ({ content, subcontent, letter }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (event) => {
    console.log("yeas");
      setIsHovered(true);

  };


  const handleMouseLeave = (event) => {
    console.log("no");

    setIsHovered(false);
  };

  return (
    <div>
    <a className="custom-card-alphabet wallet"
       onMouseEnter={handleMouseEnter}
       onMouseLeave={handleMouseLeave}
       onTouchStart={handleMouseEnter}
       onTouchEnd={handleMouseLeave}
    
    href="#">
    <div
  className="overlay"
  style={{
    border:"1px solid #a296d5",  
 
    background: isHovered
      ?`url('/media/pics/alphabet_jest/${letter}.jpg') center/cover`
      : "none",
    transform: isHovered ? "scale(4) translateZ(0)" : "none",
    backgroundColor: isHovered? "white":"none"
  }}
></div>
      <div className="circle-container"style={{ display: isHovered ? "none" : "inline" }} >
      <div className="circle" >
        <p className="custom-card-alphabet-content" style={{ margin: "0px" }}>
          {content}
        </p>
        
      </div>
      <p style={{ fontSize: "12px", margin: "0px", color:"#2b2676"}} className="day-text text-center">
        {subcontent}
      </p>
      </div>
      
    </a>
  </div>
  );
};

export default CustomCard;