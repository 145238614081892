import { React, useState, useEffect } from "react";
import { Col, Row, Card, Flex } from "antd";
import { Link, useLocation } from "react-router-dom";
import Breadcramb from "../breadcramb";
import { saveAs } from "file-saver";
import { Dropdown, Menu } from "antd";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchComponent from "../dictionary-alphabet/seach-component";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import "../unique-word/uniqueWord.css";

const CustomCountriesCard = ({ content }) => (
  <Card className="countries-custom-card" content={content}>
    <p style={{ color: "black" }}>{content}</p>
  </Card>
);

const Word = ({ categoryId, wordId }) => {
  const { pathname } = useLocation();

  const urlWord = `https://morning-plains-82582-f0e7c891044c.herokuapp.com/category/${categoryId}/words/${wordId}`;
  const urlCategory = `https://morning-plains-82582-f0e7c891044c.herokuapp.com/category/${categoryId}`;
  const urlLearnLaterWords = `https://morning-plains-82582-f0e7c891044c.herokuapp.com/learnLaterWords/${wordId}?check`;

  const [isLoading, setIsLoading] = useState(true);
  const [wordData, setWordData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [accessTokenValue, setAccessTokenValue] = useState(null);

  //fetch category datas
  const token = localStorage.getItem("user-info");

  useEffect(() => {
    const fetchData = async () => {
      // window.location.reload();
      setIsLoading(true); // Set loading state to true
      try {
        // Fetch word data
        const wordResponse = await fetch(urlWord, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (wordResponse.ok) {
          const wordDataResponse = await wordResponse.json();
          setWordData(wordDataResponse);
          setIsPlaying(true)

          if (token) {
            const tokenObject = JSON.parse(token);
            const accessTokenValue = tokenObject.access_token;
            setAccessTokenValue(accessTokenValue);

            const response = await fetch(urlLearnLaterWords, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessTokenValue}`,
              },
            });

            if (response.ok) {
              const data = await response.json();
              if (data.isLiked == true) {
                setToggle(true);
              } else {
                setToggle(false);
                console.log("yoxdu bazada");
              }
            } else {
              console.error("API error:", response.statusText);
            }
          }
        } else {
          console.error("API error:", wordResponse.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    console.log("Fetch called");
    fetchData();
  }, [urlWord, urlLearnLaterWords, accessTokenValue]);

  const handleToggle = () => {
    setToggle((prevToggle) => !prevToggle);

    if (!toggle || toggle) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenValue}`, // Replace 'yourToken' with your actual token
        },
      };
      fetch(
        `https://morning-plains-82582-f0e7c891044c.herokuapp.com/learnLaterWordToggle/${wordData.current_word.id}`,
        requestOptions
      ).then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        } else {
          console.log("isledi");
        }
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(urlCategory, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          setCategoryData(responseData);
        } else {
          console.error("API error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchData();
  }, [urlWord]);

  async function downloadPdf() {
    // try {
    //   const pdfPath = `/media/${wordData.sign_lang_pdf}`;
    //   const response = await fetch(pdfPath);
    //   const pdfBlob = await response.blob();
    //   const fileName = `${wordData.current_word.sign_lang_pdf}`;
    //   saveAs(pdfBlob, fileName);
    // } catch (error) {
    //   console.error("Error downloading PDF:", error);
    // }

    try {
      const response = await fetch(wordData.current_word.sign_lang_pdf);
      console.log(wordData);
      const pdfBlob = await response.blob();
      const fileName = "custom_file_name.pdf"; // Specify custom file name here
      saveAs(pdfBlob, fileName);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  }


  const [isPlaying, setIsPlaying] = useState(true);
  console.log(isPlaying);

  const [isHovered, setIsHovered] = useState(false);
  const [showSpeedOptions, setShowSpeedOptions] = useState(false);
  const [speed, setSpeed] = useState(1);
  const videoRef = useRef(null);

  // useEffect(() => {
  //   const video = videoRef.current;
  //   if (video) {
  //     const canvas = document.createElement("canvas");
  //     const ctx = canvas.getContext("2d");
  //     const handleLoadedData = () => {
  //       canvas.width = video.videoWidth;

  //       canvas.height = video.videoHeight;
  //       ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  //       const posterURL = canvas.toDataURL(); // Convert canvas to data URL
  //       video.setAttribute("poster", posterURL); // Set poster attribute to the data URL
  //     };

  //     video.addEventListener("loadeddata", handleLoadedData);

  //     // Clean up event listener when component unmounts
  //     return () => {
  //       video.removeEventListener("loadeddata", handleLoadedData);
  //     };
  //   }
  // }, [videoRef]);

  // const togglePlayPause = () => {
  //   if (videoRef.current.paused) {
  //     videoRef.current.play();
  //   } else {
  //     videoRef.current.pause();
  //   }

  //   setIsPlaying(!isPlaying);
  // };
  const togglePlayPause = () => {
    const video = videoRef.current;

    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  // const handleReplay = () => {
  //   videoRef.current.currentTime = 0;
  //   videoRef.current.play();
  //   setIsPlaying(true);
  // };

  const items = [
    {
      submenu: [
        {
          key: "speed-025x",
          label: "0.25x",
          onClick: () => handleSpeedChange(0.25),
        },
        {
          key: "speed-050x",
          label: "0.5x",
          onClick: () => handleSpeedChange(0.5),
        },
        {
          key: "speed-075x",
          label: "0.75x",
          onClick: () => handleSpeedChange(0.75),
        },
        {
          key: "speed-100x",
          label: "1x",
          onClick: () => handleSpeedChange(1),
        },
      ],
    },
  ];

  const handleSpeedChange = (speed) => {
    setSpeed(speed);
    videoRef.current.playbackRate = speed;
    setShowSpeedOptions(false);
    console.log(`Speed changed to ${speed}x`);
  };

  return (
    <>
      <div className="container-for-all">
        <div>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
              }}
            >
              <div className="three-body">
                <div className="three-body__dot"></div>
                <div className="three-body__dot"></div>
                <div className="three-body__dot"></div>
              </div>
            </div>
          ) : (
            <div>
              {pathname.includes("/category") ? (
                <Breadcramb
                  topCategoryName="Bütün Kategoriyalar"
                  categoryId={categoryData.id}
                  categoryName={categoryData.category_name}
                  wordId={wordData.current_word.id}
                  wordName={wordData.current_word.word_name}
                />
              ) : pathname.includes("/sentence") ? (
                <Breadcramb
                  topCategoryName="Sentences"
                  categoryId={categoryData.id}
                  categoryName={categoryData.category_name}
                />
              ) : pathname.includes("/alphabet") ? (
                <Breadcramb
                  topCategoryName="Alphabet"
                  categoryId={categoryData.id}
                  categoryName={categoryData.category_name}
                />
              ) : (
                // Render content when the path does not include "/category", "/sentence", or "/alphabet"
                <div>
                  <h1>Not a Category, Sentence, or Alphabet Page</h1>
                  {/* Other content specific to when the path does not include "/category", "/sentence", or "/alphabet" */}
                </div>
              )}

              <div className="body-margin my-4">
                <SearchComponent />

                <Row className="unique-word-container my-2 ">
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: 240,
                    }}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="video-components">
                      <div
                        className={`d-flex justify-content-center overflow-hidden custom-video-player  ${
                          isHovered ? "hovered" : ""
                        }`}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        //  style={{heigh}}
                        onClick={togglePlayPause}
                        // style={{ marginLeft: "14px", minHeight:"400px" }}
                      >
                        
                        <video
                          ref={videoRef}
                        
                          // style={{
                          //   width: "90%", // Set width to 100% for responsiveness
                          //   maxWidth: "500px", // Optionally set a maximum width
                          //   borderRadius: "10px",
                          //   overflow: "hidden",  // Set the border-radius to your desired value
                          // }}

                          id="video"
                          controls={false}
                          playsInline
                          className="video-word"
                          loop
                          autoPlay
                          muted
                          // onEnded={() => setIsPlaying(false)}
                          // src="/media/videos/wordVideo.mp4"
                          // poster="/media/pics/icons/bin.png"
                          src={wordData.current_word.sign_lang_video}
                        >
                          {/* <source src="your-video.mp4" type="video/mp4" /> */}
                          Your browser does not support the video tag.
                        </video>



                        <div 
                          className={`controls ${isPlaying ? "playing" : ""} ` }
                        >
                          <button className="togglePlayPause toggleButton">
                            {/* <FontAwesomeIcon
                            icon={isPlaying ? faPlay  :  faPause}
                          /> */}
                            <FontAwesomeIcon
                              icon={
                                isPlaying
                                  ? faPause
                                  : videoRef.current && videoRef.current.paused
                                  ? faPlay
                                  : faPause
                              }
                            />
                          </button>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center align-items-center my-2 mx-3">
                        <div>
                          <Link
                            target="_blank"
                            to={wordData.current_word.sign_lang_pdf}
                          >
                            <button
                              class="cssbuttons-io-button "
                              onClick={downloadPdf}
                            >
                              <svg
                                height="20"
                                width="20"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path
                                  d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 12h3l-4 5-4-5h3V8h2v4z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                              <span
                                style={{
                                  // fontSize: "px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                               <span className="hide-download-button"> Jestin şəklini</span>  yüklə (PDF)*
                              </span>
                            </button>
                          </Link>
                        </div>

                        <div >
                          <Dropdown
                            className="px-2"
                            overlay={
                              <Menu>
                                {items.map((item) => (
                                  <Menu.Item key={item.key}>
                                    {item.label}
                                    <Menu
                                      placement="bottomLeft"
                                      style={{
                                        fontSize: "12px",
                                        minWidth: "100px",
                                      }}
                                    >
                                      {item.submenu.map((subItem) => (
                                        <Menu.Item
                                          key={subItem.key}
                                          onClick={subItem.onClick}
                                        >
                                          {subItem.label}
                                        </Menu.Item>
                                      ))}
                                    </Menu>
                                  </Menu.Item>
                                ))}
                              </Menu>
                            }
                            placement="bottomLeft"
                            arrow={{
                              pointAtCenter: true,
                            }}
                          >
                            {/* Remove FontAwesomeIcon and replace with img tag */}
                            <img
                              src="/rush.png" // Specify the source of the image
                              alt="Tachometer" // Provide alternative text for accessibility
                              style={{
                                width: "60px", // Adjust width and height as needed
                                height: "40px",
                                cursor: "pointer",
                                color: "#4d63b8",
                              }}
                            />
                          </Dropdown>
                        </div>

                        <div className="ms-3">
                          {token && ( // Check if token exists
                            <img
                              onClick={handleToggle}
                              // onClick={() => setToggle(!toggle)}
                              src={
                                toggle
                                  ? "/media/pics/icons/toggled-heart.png"
                                  : "/media/pics/icons/heart.png"
                              }
                              width="30"
                              height="30"
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={10} lg={12} xl={6}>
                    <Card
                      id="unique-word-def"
                      style={{
                        textAlign: "center",
                        // height: "400px",
                        display: "flex",
                        alignItems: "center",
                        border: "none",
                      }}
                    >
                      <div>
                        <h5>{wordData.current_word.word_name}</h5>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: wordData.current_word.word_desc,
                          }}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "15px",
                        color: "#2b2676",
                      }}
                    >
                      <b>Uyğun sözlər</b>
                    </p>
                    <div
                      style={
                        {
                          // height: "400px",
                          // overflowY: "auto",
                          // overflowX: "hidden",
                        }
                      }
                    >
                      <Flex gap="small" vertical>
                        <Row justify="center" className="m-0" gutter={[24, 24]}>
                          {wordData.relevant_words.map((word) => (
                            <Col
                              className="card-col"
                              key={word.id}
                              xs={24}
                              sm={24}
                              md={8}
                              lg={6}
                              xl={24}
                            >
                              {word.refered_back != null ? (
                                <Link
                                  to={`/category/${word.refered_back_obj?.category_id}/words/${word.refered_back}`}
                                  // onClick={() => window.location.reload()}
                                >
                                  <CustomCountriesCard
                                    id={word.id}
                                    content={`${word.word_name} [${word.refered_back_obj?.refered_back_name}]`}
                                  />
                                </Link>
                              ) : word.word_name.endsWith("daktil") ? (
                                <CustomCountriesCard
                                  id={word.id}
                                  content={word.word_name}
                                />
                              ) : (
                                <Link
                                  to={`/category/${word.category_id}/words/${word.id}`}
                                  // onClick={() => window.location.reload()}
                                >
                                  <CustomCountriesCard
                                    id={word.id}
                                    content={word.word_name}
                                  />
                                </Link>
                              )}
                            </Col>
                          ))}
                        </Row>
                      </Flex>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Word;
