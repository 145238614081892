import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
} from "mdb-react-ui-kit";
import "./rightSide.css";

import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";

const RightSideBlog = () => {
  var urlNews = "https://morning-plains-82582-f0e7c891044c.herokuapp.com/news";

  const [isLoading, setIsLoading] = useState(true);
  const [newsData, setNewsData] = useState([]);

  //fetch category datas

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading state to true
      try {
        // Fetch word data
        const newResponse = await fetch(urlNews, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (newResponse.ok) {
          const newsDataResponse = await newResponse.json();
          setNewsData(newsDataResponse);
        } else {
          console.error("API error:", newResponse.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    
    
    <div
      style={{
        padding: "20px 0px",
      }}
    >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100vh",
            }}
          >
            <div className="three-body">
              <div className="three-body__dot"></div>
              <div className="three-body__dot"></div>
              <div className="three-body__dot"></div>
            </div>
          </div>
        ) : (
          <div>
          <MDBRow style={{ marginBottom: "30px" }}>
          {newsData.map((item, index) => (
            <MDBCol
              key={index}
              style={{ marginBottom: "30px" }}
              md="12"
              lg="6"
              xl="4"
            >
                   <Link to={`/blog/${item.id}`} className="blog-details">
                   <MDBCard className="card-blog">
                <MDBRipple
                  rippleColor="light"
                  rippleTag="div"
                  className="bg-image hover-overlay"
                >
                  <MDBCardImage
                    className="w-100 image-blogs"
                    src={item.images[0].news_img}
                    fluid
                    alt="..."
                    style={{   objectFit: "cover", // Make the image cover the entire container
                    // width: "100%",      // Ensure the image width covers the container width
                    // height: "100%",     // Ensure the image height covers the container height
                    objectPosition: "center center", // Center the image horizontally and vertically
                    overflow: "hidden"  
                  }}
                  />
                  <a>
                    <div
                      className="mask"
                      style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                    ></div>
                  </a>
                </MDBRipple>
                <MDBCardBody>
                  <MDBCardTitle className="subtitle-post-card">
                    {item.date}
                  </MDBCardTitle>
                  <MDBCardTitle>{item.title}</MDBCardTitle>
                  <MDBCardText>
                    {item.content.split(" ").slice(0, 15).join(" ")}{" "}
                    {item.content.split(" ").length > 15 && "..."}
                  </MDBCardText>
                  <Link to={`/blog/${item.id}`} className="button-read-more">
                    <p>Ətraflı</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      ></path>
                    </svg>
                  </Link>
                </MDBCardBody>
              </MDBCard>
                   </Link>
            
            </MDBCol>
          ))}
        </MDBRow>
  
      
  
          </div>

          
        )}
       
    

     
    </div>
  );
};

export default RightSideBlog;
