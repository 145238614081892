import React, { useState } from "react";
import { Row, Col, Input, List } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { useRef } from "react";
import debounce from "lodash.debounce";
import { hexToRgb } from "@mui/material";
import { height } from "@mui/system";
import "./styles.css";

const { Search } = Input;

const SearchComponent = () => {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const onSearch = async (value) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://morning-plains-82582-f0e7c891044c.herokuapp.com/words/search?q=${value}&per_page=20`
      );

      if (response.data == 0) {
        setLoading(false);

        return;
      }
      // Limit the results to the top 6
      // const topResults = response.data.slice(0, 6);

      setSearchResults(response.data.words);
      console.log(searchResults);
      console.log(response.data.words);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = debounce(onSearch, 300);

  const handleInputChange = (e) => {
    const { value } = e.target;

    if (document.activeElement === e.target) {
      const { value } = e.target;
      debouncedSearch(value);
    } else {
      setLoading(false);
    }
  };

  // const onFocusOut = (e) => {
  //   const { value } = e.target;

  //   if (document.activeElement === e.target) {
  //     const { value } = e.target;
  //     debouncedSearch(value);
  //   } else {
  //     setLoading(false);
  //   }
  // };


 

  const handleClickOutside = (event) => {
    if (!document.getElementById("search-box").contains(event.target)) {
      // Clicked outside the search box, hide the list
      setSearchResults([]);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Row
      className="page-top-title justify-content-start
     align-items-center"
    >
      <Col
        className="seach-title-dictionary "
        xs={24}
        sm={4}
        md={4}
        lg={4}
        xl={4}
      >
        <div className="page-top-name">
          <p>Lüğət</p>
        </div>
      </Col>
      <Col
        xs={24}
        sm={20}
        md={20}
        lg={20}
        xl={10}
        className="seach-title-dictionary p-0"
      >
        <Col
          xs={24}
          sm={24}
          md={20}
          lg={20}
          xl={10}
          className="seach-title-dictionary"
        >
          <div className="Card">
            <div className="CardInner">
              {/* <label>Sözü axtarın</label> */}
              <div
                id="search-box"
                className="search-box"
                style={{ position: "relative" }}
              >
                <div className="search-component-container">
                  <div
                    className="Icon"
                    onClick={onSearch}
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#657789"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-search"
                      onSearch={onSearch}
                      style={{ cursor: "pointer" }}
                    >
                      <circle cx="11" cy="11" r="8" />
                      <line x1="21" y1="21" x2="16.65" y2="16.65" />
                    </svg>
                  </div>
                  <div className="InputContainer">
                    <input
                      className="search-component-input"
                      placeholder="Sözü daxil edin..."
                      onChange={handleInputChange}
                      // value={inputValue}
                    />
                  </div>
                </div>

                {loading && searchResults.length === 0 && (
                  <List.Item style={{ padding: "0px", position: "absolute" }}>
                    <Link disabled className="search-result-link">
                      Tapilmadi
                    </Link>
                  </List.Item>
                )}
              </div>
              <List
                style={{
                  position: "absolute",
                  maxHeight: "200px",
                  width: "100%",
                  zIndex: "999",
                  overflowY: "auto", // Enable vertical scrolling
                  // scrollbarWidth: "thin", // Change scrollbar width if needed
                  scrollbarColor: "rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1)", // Change scrollbar color if needed
                }}
                loading={loading}
                dataSource={searchResults}
                renderItem={(item) =>
                  item.refered_back != null ? (
                    <Link
                      to={`/category/${item.referenced_word.category_id}/words/${item.refered_back}`}
                      className="search-result-link"
                    >
                      <List.Item style={{ padding: "0px" }}>
                        {item.word_name}
                      </List.Item>
                    </Link>
                  ) : item.word_name.endsWith("daktil") ? (
                    <List.Item style={{ padding: "0px" }}>
                      <Link disabled className="search-result-link">
                        {item.word_name}
                      </Link>
                    </List.Item>
                  ) : (
                    <Link
                      to={`/category/${item.category_id}/words/${item.id}`}
                      className="search-result-link"
                    >
                      <List.Item style={{ padding: "0px" }}>
                        {item.word_name}
                      </List.Item>
                    </Link>
                  )
                }
              />
            </div>
          </div>
        </Col>
      </Col>
    </Row>
  );
};

export default SearchComponent;
