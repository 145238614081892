import React from "react";
import "./home.css";
import SearchComponent from "../dictionary-alphabet/search-for-bg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const WelcomeBg = () => {
  return (
    <div className="container-for-all">
      <div className="container-welcome">
        <div className="video-background">
          <Carousel
            dynamicHeight="false"
            autoPlay={true}
            infiniteLoop={true}
            interval="2300"
            showThumbs={false}
            swipeable={false}
            showStatus={false}
            showIndicators={false}
            className="mains_lide_desktop"
          >
            <div className="welcome-carousel">
            <video className="home-welcome-video " autoPlay loop muted>
            <source src="/media/video/bg-video.mp4" type="video/mp4" />
          </video>
            </div>
          </Carousel>
          <div className="content">
            <h1>Azərbaycan jest dili lüğətinə xoş gəldiniz!</h1>
            <SearchComponent />
          </div>
        </div>
      </div>
    </div>
  );
};
export default WelcomeBg;
