import React from "react";
import { Link } from "react-router-dom";
import "./alphabet.css";
import { Tabs, Card } from "antd";
import { Flex } from "antd";
import { Col, Row } from "antd";
import { useState, useEffect } from "react";
import { Pagination } from "antd";
import CustomCard from "./custom-card";
import SearchComponent from "./seach-component";
const { TabPane } = Tabs;

// const CustomCard = ({ title, content, subcontent,imageURL }) => (
  
//   <div>
//     <a className="custom-card-alphabet wallet" href="#">
//       <div className="overlay"></div>
//       <div className="circle-container">
//       <div className="circle" style={{ background: `url(${imageURL}) center/cover` }}>
//         <p className="custom-card-alphabet-content" style={{ margin: "0px" }}>
//           {content}
//         </p>
        
//       </div>
//       <p style={{ fontSize: "12px", margin: "0px", color:"#2b2676"}} className="day-text">
//         {subcontent}
//       </p>
//       </div>
      
//     </a>
//   </div>
//   //  <Card  className='custom-card-alphabet '  content={content} subcontent={subcontent}  style={{ width: 200, height: 150 }}>
//   //   <p className="custom-card-alphabet-text">{content}</p>
//   //   <p style={{fontSize:"14px"}}  className="cusom-card-subtitle">{subcontent}</p>
//   // </Card>

//   // <Card className='custom-card-alphabet' title={title} style={{ width: 300, height:"90px" }}>
//   //   <p className="custom-card-alphabet-text">{content}</p>
//   //   <p style={{fontSize:"14px"}}  className="cusom-card-subtitle">{subcontent}</p>
//   // </Card>
//   // // circle la olan
// );

const CustomCountriesCard = ({ content }) => (
  <Card
    className="countries-custom-card"
    content={content}
    style={{ backgroundColor: "#F0E7FF !important" }}
  >
    <p style={{ color: "black" }}>{content}</p>
  </Card>
);


const CustomAllCategoryCard = ({ id, content, subcontent }) => (
  // <Card  className='custom-card custom-all-category-card' id={id} content={content} subcontent={subcontent}  style={{ width: 200, height: 150 }}>
  //   <p className="cusom-card-title">{content}</p>
  //   <p style={{fontSize:"14px"}}  className="cusom-card-subtitle">{subcontent}</p>
  // </Card>

  <div
    className="custom-card"
    id={id}
    content={content}
    subcontent={subcontent}
  >
    <div className="card-content">
      <p className="content-text">
        <span style={{ fontSize: "20px" }}>{content}</span>
      </p>
      <p style={{ fontSize: "12px" }} className="day-text">
        {subcontent}
      </p>
    </div>
  </div>

  // // circle la olan
  // <div >
  // <a className="card wallet" href="#">
  //   <div className="overlay"></div>
  //   <div className="circle">
  //     <span>Ayan</span>
  //   </div>
  //   <p style={{margin:"0px" }} >{content}</p>
  //   <p style={{fontSize:"12px", margin:"0px" }} className="day-text">{subcontent}</p>
  // </a>
  // </div>
);
// <style>
// .custom-card-alphabet:hover .circle:after {
//   content: "";
//   width: 100%;
//   height: 100%;
//   display: block;
//   position: absolute;
//   background: url('/public/logo.jpg') center/cover; /* Replace with your image path */
//   border-radius: 50%;
//   transition: transform 0.4s ease-out;
//   transform: rotateY(180deg);

// }
// </style>

const AlphabetTab = ({ element }) => {
  const urlCategories = `https://morning-plains-82582-f0e7c891044c.herokuapp.com/categories`;
  const urlSentences = `https://morning-plains-82582-f0e7c891044c.herokuapp.com/sentences`;
  const urlAlphabet = `https://morning-plains-82582-f0e7c891044c.herokuapp.com/alphabets_summary`;

  const [categoriesData, setCategoriesData] = useState([]);
  const [sentencesData, setSentencesData] = useState([]);
  const [alphabetData, setAlphabetData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(urlCategories, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          setCategoriesData(responseData);
        } else {
          console.error("API error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(urlAlphabet, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          setAlphabetData(responseData);
        } else {
          console.error("API error:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setIsLoading2(false);
      }
    };
    fetchCategories();
  }, []);

  const [activeKey, setActiveKey] = useState("1"); // Default active key

  useEffect(() => {
    // Set the active key based on the value of the 'element' prop
    if (element === "Bütün Kategoriyalar") {
      setActiveKey("1"); // "All Categories" tab key
    } else if (element === "Əlifba") {
      setActiveKey("2"); // "Alphabet" tab key
    } else if (element === "Cümlələr") {
      setActiveKey("3"); // "Alphabet" tab key
    }
  }, [element]);

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  //sentences tab
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);

  const fetchData = async (page) => {
    try {
      const response = await fetch(`${urlSentences}?page=${page}&per_page=24`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setSentencesData(responseData);
        setTotalNumberOfPages(responseData.pagination.total_items);
        setCurrentPage(responseData.pagination.page);
      } else {
        console.error("API error:", response.statusText);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setIsLoading3(false);
    }
  };

  useEffect(() => {
    fetchData(1); //  initial data
  }, []); // empty  array ensures the effect runs only once

  const handlePageChange = (page) => {
    fetchData(page);
  };

  return (
    <div className="container-for-all">
        <div className="body-margin">

<SearchComponent/>

<Tabs
style={{ justifyContent: "center" }}
defaultActiveKey={activeKey}
activeKey={activeKey}
onChange={handleTabChange}
>
<TabPane id="all-categories-tab" tab="Bütün Kategoriyalar" key="1">
  <div>
    <Flex gap="small" vertical>
      <div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100vh",
            }}
          >
            <div className="three-body">
              <div className="three-body__dot"></div>
              <div className="three-body__dot"></div>
              <div className="three-body__dot"></div>
            </div>
          </div>
        ) : (
          <Row gutter={[16, 16]}>
            {categoriesData.map((category, index) => (
              <Col
                key={index}
                xs={{ span: 12 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 4 }}
              >
                <Link to={`/category/${category.id}/words`}>
                  <CustomAllCategoryCard
                    id={category.id}
                    content={category.name}
                    subcontent={`Jestlər: ${category.words_count} `}
                  
                  />
                </Link>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </Flex>
  </div>
</TabPane>

<TabPane id="alphabet-tab" tab="Əlifba" key="2">
  <div>
    <Flex gap="small" vertical>
      {isLoading2 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <div className="three-body">
            <div className="three-body__dot"></div>
            <div className="three-body__dot"></div>
            <div className="three-body__dot"></div>
          </div>
        </div>
      ) : (
        <Row gutter={[16, 16]}>
          {alphabetData.map((letter, index) => (
            <Col
              key={index}
              xs={{ span: 12 }}
              sm={{ span: 8 }}
              md={{ span: 8 }}
              lg={{ span: 3 }}
            >
              <Link to={`/alphabet/${letter.letter_name}`}>
                <CustomCard

                  content={letter.letter_name}
                  subcontent={`Jestlər: ${letter.number_of_words} `}
                  letter={letter.letter_name.toLowerCase()} 
                />
              </Link>
            </Col>
          ))}
        </Row>
      )}
    </Flex>
  </div>
</TabPane>

{/* <TabPane id='configurations-tab' tab="Configurations" key="3">
  <div >
    <Flex gap="small" vertical>
      <Row gutter={[16, 16]}>
        {alphabet.map((letter, index) => (
          <Col key={index} xs={{ span: 12 }} sm={{ span: 12 }} md={{ span:  8}} lg={{ span: 6 }}>
         
           <Link to={`/dictionary/word`}>
               <CustomCard className="configurations-card" content="word" />
            </Link>

          </Col>
        ))}
      </Row>
    </Flex>
  </div>
</TabPane> */}

<TabPane id="sentences-tab" tab="Cümlələr" key="3">
  <div>
    <Flex gap="small" vertical>
      {isLoading3 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <div className="three-body">
            <div className="three-body__dot"></div>
            <div className="three-body__dot"></div>
            <div className="three-body__dot"></div>
          </div>
        </div>
      ) : (
        <div>
          <Row gutter={[16, 16]}>
            {sentencesData.sentences &&
            sentencesData.sentences.length > 0 ? (
              sentencesData.sentences.map((sentence, index) => (
                <Col 
                 className="card-col"
                  key={index}
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                >
                  <Link to={`/cumleler/${sentence.id}`}>
                    <CustomCountriesCard
                      className="sentences-card"
                      content={
                        sentence.text.length > 48
                          ? `${sentence.text.slice(0, 45)}...`
                          : sentence.text
                      }
                      
                    ></CustomCountriesCard>
                  </Link>
                </Col>
              ))
            ) : (
              <p>No sentences available.</p>
            )}
          </Row>
          <div className="d-flex justify-content-center mt-4">
            <Pagination
              style={{ marginTop: "10px" }}
              current={currentPage}
              defaultCurrent={1}
              total={totalNumberOfPages}
              pageSize={24} //  page size
              onChange={handlePageChange}
            />
          </div>
        </div>
      )}
    </Flex>
  </div>
</TabPane>
</Tabs>
</div>

      </div>
  
  );
};

export default AlphabetTab;
