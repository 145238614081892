
import { React, useState, useEffect } from "react";

import Layout from "../Layout/layout";
import "./rightSide.css";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useParams } from "react-router-dom"; // Import useParams hook
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBTypography,
} from "mdb-react-ui-kit";
import {
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn,
    MDBRipple,
  } from "mdb-react-ui-kit";

const UniqueBlog = () => {
    const { id } = useParams(); 
    var urlNews=`https://morning-plains-82582-f0e7c891044c.herokuapp.com/news/${id}`;

    const [isLoading, setIsLoading] = useState(true);
    const [newsData, setNewsData] = useState([]);
  
    //fetch category datas
  
    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true); // Set loading state to true
        try {
          // Fetch word data
          const newResponse = await fetch(urlNews, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (newResponse.ok) {
            const newsDataResponse = await newResponse.json();
            setNewsData(newsDataResponse);
            console.log(newsData);
  
            
          } else {
            console.error("API error:", newResponse.statusText);
          }
        } catch (error) {
          console.error("Fetch error:", error);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
  return (
    <Layout>
          {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100vh",
            }}
          >
            <div className="three-body">
              <div className="three-body__dot"></div>
              <div className="three-body__dot"></div>
              <div className="three-body__dot"></div>
            </div>
          </div>
        ) : (
            <div className="body-margin d-flex justify-content-center">
      <MDBRow className="unique-blog-row-width" style={{ marginBottom:"40px"}}>
            {/* <MDBCol sm="4" md="4" lg="4" xl="4">

            </MDBCol> */}
    
            <MDBCol  sm="12" md="12" lg="12" xl="12">
            <MDBCardHeader className="unique-blog-title py-5">{newsData.title}</MDBCardHeader>

            <MDBRipple
              rippleColor="light"
              rippleTag="div"
              className="bg-image blog-image-div hover-overlay"
            >
              <MDBCardImage 
                className=" unique-image-blogs"
               

                src={newsData.images[0].news_img}
                fluid
                alt="..."
              />
              <a>
                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                ></div>
              </a>
            </MDBRipple>
    
              <MDBCard>
                {/* <MDBCardHeader></MDBCardHeader> */}
                <MDBCardBody>
                  <MDBTypography blockquote className="mb-0">
                    <p className="unique-blog-desc"  dangerouslySetInnerHTML={{
                          __html: newsData.content,
                        }}>
                        
                     {/* {newsData.content} */}
                    </p>
                    {/* <footer className="blockquote-footer">
                      Someone famous in{" "}
                      <cite title="Source Title">Source Title</cite>
                    </footer> */}
                  </MDBTypography>
                  <a href="https://www.nar.az/">https://www.nar.az/</a>
                </MDBCardBody>
              </MDBCard>
             
            </MDBCol>
          </MDBRow>
            </div>
      
        )}

   
    </Layout>
  );
};

export default UniqueBlog;