import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";


// import jwtDecode from 'jwt-decode';

import { Input } from "antd";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from "mdb-react-ui-kit";
import "./navbar.css";

function Navbar() {
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("AZ");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [openBasic, setOpenBasic] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

 
  useEffect(() => {
    // const authToken = localStorage.getItem("user-info");
    // setIsLoggedIn(!!authToken);

    // if (authToken) {
    //   const parts = authToken.split(".");
    //   const payload = atob(parts[1]);
    //   const payloadObj = JSON.parse(payload);
    //   const userImg = payloadObj.user_img;

    //   setProfilePictureUrl(userImg);
    //   console.log(userImg);
    // }
    const fetchData = async () => {
      const authToken = localStorage.getItem("user-info");
      if (authToken) {
        try {
          const tokenObject = JSON.parse(authToken);
          const accessTokenValue = tokenObject.access_token;
          setIsLoggedIn(true);
          const response = await axios.get(
            "https://morning-plains-82582-f0e7c891044c.herokuapp.com/user/info",
            {
              headers: {
                Authorization: `Bearer ${accessTokenValue}`,
              },
            }
          );
          setProfilePictureUrl(
            response.data.user_info.user_img ||"/media/logo.png"
            //  "/media/pics/member.png"
          );
        } catch (error) {
          localStorage.removeItem("user-info");
          console.log("sildi");
      toast.success("Logged out successfully!", { autoClose: 2000 });
      window.location.reload(); // Reload the page to reflect the changes
      navigate("/login"); 
          console.error("Error decoding token:", error.message);
        }
      }
    };
    fetchData();
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const changeLanguage = (language) => {
    setSelectedLanguage(language);
    setDropdownOpen(false);
  };

  const scrollToAboutSection = (event) => {
    const aboutSection = document.getElementById("about-section");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="container-for-all">
          <MDBNavbar expand="lg"  style={{backgroundColor:"rgb(242 246 250)"}}>
      <MDBContainer className="navbar-container" fluid>
        <MDBNavbarBrand href="#">
          <a href="/home">
            <img src="/logo.jpg" height="30" alt="" loading="lazy" />
            <span style={{ fontWeight: "bold", color:"#2b2676 " }}>
              Jest Dili
            </span>
          </a>
        </MDBNavbarBrand>

        <MDBNavbarToggler
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setOpenBasic(!openBasic)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar open={openBasic}>
          <MDBNavbarNav className="justify-content-end mr-auto mb-2 mb-lg-0">
            <MDBNavbarNav className="justify-content-end mr-auto mb-2 mb-lg-0">
              <MDBNavbarItem>
                <MDBNavbarLink
                  style={{ color:"#2b2676" }}
                  active
                  aria-current="page"
                  href="/home"
                >
                  Ana Səhifə
                </MDBNavbarLink>
              </MDBNavbarItem>
              {/* <MDBNavbarItem>
                <MDBNavbarLink
                  active
                  aria-current="page"
                  style={{ color: "#2b2676" }}
                  href="/home#about-section"
                  onClick={scrollToAboutSection}
                >
                  Haqqında
                </MDBNavbarLink>
              </MDBNavbarItem> */}
              <MDBNavbarItem>
                <MDBNavbarLink
                  style={{ color:"#2b2676" }}
                  active
                  aria-current="page"
                  href="/dictionary"
                >
                  Lüğət
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink
                  style={{ color:"#2b2676" }}
                  active
                  aria-current="page"
                  href="/blog"
                >
                  Xəbərlər
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink
                  style={{ color:"#2b2676" }}
                  active
                  aria-current="page"
                  href="/contact"
                >
                  Əlaqə
                </MDBNavbarLink>
              </MDBNavbarItem>
              {/* <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle
                    style={{ color: "#2b2676" }}
                    tag="a"
                    className="nav-link"
                    role="button"
                    onClick={toggleDropdown}
                  >
                    {selectedLanguage}
                  </MDBDropdownToggle>
                  {isDropdownOpen && (
                    <MDBDropdownMenu className="">
                      <MDBDropdownItem
                        style={{ color: "#2b2676" }}
                        link
                        onClick={() => changeLanguage("AZ")}
                      >
                        Azərbaycan
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        style={{ color: "#2b2676" }}
                        link
                        onClick={() => changeLanguage("EN")}
                      >
                        İngilis
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        style={{ color: "#2b2676" }}
                        link
                        onClick={() => changeLanguage("RU")}
                      >
                        Rus
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  )}
                </MDBDropdown>
              </MDBNavbarItem> */}
            </MDBNavbarNav>
          </MDBNavbarNav>

          {isLoggedIn ? (
            <div className="profile-section">
              <a href="/profile">
                <div className="profile-picture">
                  <div
                    className="circular"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      border: "1px solid #2b2676",
                      background: profilePictureUrl
                        ? `url("${profilePictureUrl}") center/cover`
                        : 'url("https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2220431045.jpg") center/cover',
                      backgroundSize: profilePictureUrl ? "cover" : "",
                    }}
                  />
                </div>
              </a>
            </div>
            
          ) : (
            <div className="d-flex input-group w-auto">
              <div className="navbar-login-signup navbar-login">
                <MDBNavbarLink active aria-current="page" href="/login">
                  Giriş
                </MDBNavbarLink>
                <span></span>
              </div>
              <div className="navbar-login-signup navbar-signup">
                <MDBNavbarLink active aria-current="page" href="/signup">
                  Qeydiyyat
                </MDBNavbarLink>
              </div>
            </div>
          )}
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>

    </div>

  );
}

export default Navbar;
