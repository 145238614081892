import React from "react";
import Layout from "../components/Layout/layout";
import { useParams } from "react-router-dom";

import UniqueLetterWordComponent from "../components/unique-letter/uniqueLetterWordComponent";
const UniqueLetterWord = () => {
  const { letter, wordId } = useParams();

  return (
    <Layout>
      <UniqueLetterWordComponent
        letter={letter}
        wordId={wordId}
      ></UniqueLetterWordComponent>
    </Layout>
  );
};

export default UniqueLetterWord;
