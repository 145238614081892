import React from 'react'
import Layout from '../components/Layout/layout'
import { useParams } from 'react-router-dom'
import UniqueSentenceComponent from '../components/unique-sentence/uniqueSentenceComponent'

const UniqueSentence = () => {
  const { sentenceId } = useParams();

  return (
    <Layout>
    <UniqueSentenceComponent sentenceId={sentenceId}></UniqueSentenceComponent>
    </Layout>
  )
}

export default UniqueSentence
