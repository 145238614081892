import React from "react";
import "./footer.css";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

const Footer = () => {
  const sites = ["facebook", "twitter", "google", "dribbble", "skype"];
  return (
    <div className="container-for-all">
      <div className="mt-5">
        <MDBFooter
          style={{
          
            backgroundColor: "rgb(242 246 250)",
            borderTop: "2px solid rgb(235 242 255)",
          }}
          className="text-center pt-5 text-lg-start text-muted "
        >
          <section className="d-flex justify-content-center justify-content-lg-between "></section>

          {/* <MDBContainer className=" tex-start mt-5 p-0">
          
          </MDBContainer> */}
          <MDBRow className="mt-3 body-margin">
            <MDBCol md="6" lg="4" xl="4" className="mx-auto mb-4">
              <h6
                className="text-start text-uppercase fw-bold mb-4"
                style={{
                  color: "rgb(43, 38, 118)",
                  fontWeight: "600",
                  fontSize: "25px",
                }}
              >
                <MDBIcon
                  style={{ color: "#2B2676" }}
                  icon="gem"
                  className="me-3"
                />
                JestDili
              </h6>
              <p className="about-jest-dili">
                Eşitmə və nitq məhdudiyyəti olan şəxslərin və onların
                yaxınlarının istifadəsi məqsədi ilə Azərbaycan jest dili lüğəti
                və bu lüğətin yerləşdirildiyi veb-sayt “Karlara dəstək” İctimai
                Birliyi tərəfindən hazırlanıb.
              </p>

              <div>
                <div className="social-btns">
                  <p
                    style={{
                      color: "rgb(43, 38, 118)",
                      fontWeight: "600",
                      padding: "10px 0px",
                    }}
                  >
                    Sosial şəbəkələrdə bizi izləyin
                  </p>
                </div>
                <div className="footer-all-components">
                  <a
                    href="https://www.instagram.com/jestdili.az/"
                    className="me-4 text-reset"
                  >
                    <MDBIcon
                      style={{ color: "#2B2676" }}
                      fab
                      icon="instagram"
                    />
                  </a>
                  {/* <a
                      href="https://www.instagram.com/jestdili.az/"
                      className="me-4 text-reset"
                    >
                      <MDBIcon style={{ color: "#2B2676" }} fab icon="google" />
                    </a>

                    <a
                      href="https://www.instagram.com/jestdili.az/"
                      className="me-4 text-reset"
                    >
                      <MDBIcon style={{ color: "#2B2676" }} fab icon="google" />
                    </a> */}
                  {/* <a
                      href="https://www.instagram.com/jestdili.az/"
                      className="me-4 text-reset"
                    >
                      <MDBIcon
                        style={{ color: "#2B2676" }}
                        fab
                        icon="twitter"
                      />
                    </a> */}
                  {/* <a
                      href="https://www.instagram.com/jestdili.az/"
                      className="me-4 text-reset"
                    >
                      <MDBIcon
                        style={{ color: "#2B2676" }}
                        fab
                        icon="facebook"
                      />
                    </a> */}
                </div>

                {/* <a href="" className="me-4 text-reset">
                    <MDBIcon color="secondary" fab icon="linkedin" />
                  </a>
                  <a href="" className="me-4 text-reset">
                    <MDBIcon color="secondary" fab icon="github" />
                  </a> */}
              </div>
            </MDBCol>
            <MDBCol md="6" lg="4" xl="4" className="mx-auto  color-footer ">
              <div className="social-btns">
                <p
                  style={{
                    color: "rgb(43, 38, 118)",
                    fontWeight: "600",
                    padding: "10px 0px",
                  }}
                >
                  Səhifələrimiz
                </p>
              </div>
              <MDBRow className="mt-3">
                <MDBCol md="4" lg="6" xl="6">
                  <div className="footer-all-components">
                    <p>
                      <a href="/home" className="text-reset">
                        Ana səhifə
                      </a>
                    </p>
                    <p>
                      <a href="/dictionary" className="text-reset">
                        Lüğət
                      </a>
                    </p>
                    <p>
                      <a href="/blog" className="text-reset">
                        Xəbərlər
                      </a>
                    </p>
                  </div>
                </MDBCol>

                <MDBCol md="4" lg="6" xl="6">
                  <div className="footer-all-components">
                    <p>
                      <a href="/profile" className="text-reset">
                        Profil
                      </a>
                    </p>

                    {/* <p>
                        <a href="#!" className="text-reset">
                          Dəstək Mərkəzi
                        </a>
                      </p> */}

                    <p>
                      <a href="/faq" className="text-reset">
                        F.A.Q
                      </a>
                    </p>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>

            <MDBCol md="4" lg="4" xl="3" className=" mb-md-0 ">
              <div className="social-btns">
                <p
                  style={{
                    color: "rgb(43, 38, 118)",
                    fontWeight: "600",
                    padding: "10px 0px",
                  }}
                >
                  Əlaqə
                </p>
              </div>

              <div className="footer-all-components">
                <p>
                  <a
                    href="mailto:info@jestdili.az"
                    style={{ color: "#2B2676", textDecoration: "none" }}
                  >
                    <MDBIcon icon="envelope" className="me-3" />
                    <span style={{ color: "rgb(43, 38, 118)" }}>
                      info@jestdili.az
                    </span>
                  </a>
                </p>
                <p>
                  <a
                    href="tel:+994703175644"
                    style={{ color: "#2B2676", textDecoration: "none" }}
                  >
                    <MDBIcon icon="phone" className="me-3" />
                    <span style={{ color: "rgb(43, 38, 118)" }}>
                      070 888 83 33
                    </span>
                  </a>
                </p>
              </div>
            </MDBCol>
          </MDBRow>

          <div
            className="text-center p-4"
            style={{ backgroundColor: "#2B2676", color: "white" }}
          >
            © <em>2019-2024</em>{" "}
            <a className="text-reset fw-bold" href="https://jestdili.az/">
              jestdili.az
            </a>{" "}
            <br />
            <em style={{ fontSize: "15px" }}>
              Səhifədəki materiallardan istifadə olunarkən rəsmi sayta istinad
              edilməsi mütləqdir.
            </em>
          </div>
        </MDBFooter>
      </div>
    </div>
  );
};

export default Footer;
