import axios from "axios";
import React, { useState } from "react";
import Layout from "../components/Layout/layout";
import "../components/contact/contact.css";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Card } from "antd";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    context: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [isSuccess, setIsSuccess] = useState(false);
  // const [hasError, setHasError] = useState(false);

  // const validateForm = () => {
  //   return formData.field1 && formData.field2;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const isFormValid = validateForm();

    // if (isFormValid) {
    //   setHasError(false);
    // } else {
    //   setHasError(true);
    //   return;

    // }

    console.log("Form submitted:", formData);
    setIsSuccess(true);

    try {
      const response = await axios.post(
        "https://morning-plains-82582-f0e7c891044c.herokuapp.com/user/add_message",
        formData
      );
      console.log("Message sent successfully:", response.data);

      setFormData({
        name: "",
        email: "",
        context: ""
      });
      
    } catch (error) {
      console.error("Error sending message:", error);
      // setIsSuccess(false);
    }

    console.log(formData);
  };

  return (
    <Layout>
       <div className="container-for-all">
       <section>
        <div className="body-margin">
          <div className="contact-container-full  my-5 ">
            <MDBRow
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MDBCol
                sm="12"
                md="12"
                lg="7"
                xl="7"
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  borderRadius: "15px",
                }}
              >
                <MDBCol sm="12" md="12" lg="12" xl="12">
                  <Card 
                    bordered={false}
                    className="left-side-top"
                    style={{ border: "none", boxShadow: "none" }}
                  >
                    <div className="left-side-top-content">
                      <h3 className="left-top-title">
                        Təklif və ya iradlarınızı bizimlə bölüşün!
                      </h3>
                      <span>
                        Bizimlə əlaqə saxlamaq üçün aşağıdakı məlumatları
                        istifadə edə bilərsiniz. Ən qısa zamanda sizinlə əlaqə
                        saxlayacağıq. Diqqətiniz üçün təşəkkür edirik!
                      </span>
                    </div>

                    <div className="left-top-icons d-flex">
                      <FontAwesomeIcon
                        icon={faFacebookSquare}
                        size="2x"
                        className="mx-2"
                      />
                      <FontAwesomeIcon
                        icon={faInstagram}
                        size="2x"
                        className="mx-2"
                      />
                      <FontAwesomeIcon
                        icon={faYoutube}
                        size="2x"
                        className="mx-2"
                      />
                      <FontAwesomeIcon
                        icon={faPhone}
                        size="2x"
                        className="mx-2"
                      />
                    </div>
                  </Card>
                </MDBCol>

                <MDBCol sm="12" md="12" lg="12" xl="12">
                  <div className="form-container">
                    <div
                      className="form-content"
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <div
                        class="card"
                        style={{ border: "none", boxShadow: "none" }}
                      >
                        <span class="title">
                          Zəhmət olmasa aşağıdakı formu doldurun:
                        </span>

                        <form class="contact-page-form" onSubmit={handleSubmit}>
                          <div class="group">
                            <input
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleInputChange}
                              placeholder="‎"
                              required
                            />
                            <label for="name">Ad və Soyad</label>
                          </div>

                          <div class="group">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleInputChange}
                              placeholder="‎"
                              required
                            />
                            <label for="email">E-poçt Ünvanı</label>
                          </div>

                          <div class="group">
                            <textarea
                              // id="context"
                              name="context"
                              value={formData.context}
                              onChange={handleInputChange}
                              placeholder="‎"
                              rows="5"
                              required
                            ></textarea>
                            <label for="context">Şərh</label>
                          </div>

                          <button type="submit">Göndər</button>
                        </form>

                        {/* Render success message conditionally */}
                        {isSuccess && (
                          <div className="success-message">
                            <p>
                              Sorğunuz göndərildi. Tezliklə sizinlə əlaqə
                              saxlayacağıq!
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </MDBCol>
              </MDBCol>
            </MDBRow>
          </div>
        </div>
      </section>
       </div>
      
     
    </Layout>
  );
}